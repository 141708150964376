// src/components/audio/AudioInterruption.js
import { useCallback, useEffect, useState, useRef } from 'react';
import useAudioRecorder from '../../hooks/useAudioRecorder';
import interruptionService from '../../services/interruptionService';

/**
 * Optimized hook to manage audio interruption flow with reduced latency
 * and complete microphone release functionality
 * @param {Object} options - Configuration options
 * @param {string} options.userId - User ID
 * @param {string} options.channelId - Channel ID
 * @param {string} options.contentId - Content ID
 * @param {number} options.chapterNumber - Current chapter number
 * @param {Function} options.onInterruptionComplete - Callback when interruption is processed
 * @param {Function} options.onError - Callback for error handling
 * @returns {Object} Interruption state and controls
 */
const useAudioInterruption = ({
  userId,
  channelId,
  contentId,
  chapterNumber,
  onInterruptionComplete,
  onError
}) => {
  // Use a single state object for related states to reduce renders
  const [interruptionState, setInterruptionState] = useState({
    isProcessing: false,
    processingStatus: null,
    isPreparing: false
  });
  const [responseAudio, setResponseAudio] = useState(null);
  
  // Extract individual states for cleaner code
  const { isProcessing, processingStatus, isPreparing } = interruptionState;
  
  // Pre-allocate AbortController
  const abortControllerRef = useRef(null);
  
  // Audio recorder hook
  const {
    isRecording,
    recordedBlob,
    recordingDuration,
    hasPermission,
    error: recorderError,
    isLimitReached,
    startRecording,
    stopRecording,
    requestMicrophoneAccess,
    checkPermissionStatus,
    resetRecorder,
    releaseMediaStream // Use specific method to release the media stream
  } = useAudioRecorder({
    maxDuration: 120000, // 2 minutes
  });
  
  // Performance tracking
  const performanceMetricsRef = useRef({
    recordingStartTime: 0,
    recordingEndTime: 0,
    processingStartTime: 0,
    processingEndTime: 0
  });
  
  // Effect to handle recording limit reached
  useEffect(() => {
    if (isRecording && isLimitReached) {
      console.log('[AudioInterruption] Recording limit reached, stopping automatically');
      stopRecording();
      // Also release the media stream immediately
      releaseMediaStream();
    }
  }, [isRecording, isLimitReached, stopRecording, releaseMediaStream]);
  
  /**
   * Completely release the media stream (microphone)
   * This function is exported for external components to call
   */
  const completelyReleaseMedia = useCallback(() => {
    console.log('[AudioInterruption] Completely releasing media stream');
    if (releaseMediaStream) {
      releaseMediaStream();
    }
  }, [releaseMediaStream]);
  
  // Reset function
  const reset = useCallback(() => {
    // Use functional update to batch state changes
    setInterruptionState({
      isProcessing: false,
      processingStatus: null,
      isPreparing: false
    });
    
    setResponseAudio(null);
    resetRecorder();
    
    // Abort any in-progress requests
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  }, [resetRecorder]);
  
  // Start recording function
  const startInterruption = useCallback(async () => {
    try {
      // Batch state updates
      setInterruptionState(prev => ({
        ...prev,
        isPreparing: true
      }));
      
      // Track performance
      performanceMetricsRef.current.recordingStartTime = performance.now();
      
      // Start recording with the active stream (the mic should already be activated)
      const result = await startRecording();
      
      if (!result) {
        throw new Error('Failed to start recording');
      }
      
      // Update state
      setInterruptionState(prev => ({
        ...prev,
        isPreparing: false
      }));
      
      return true;
    } catch (error) {
      console.error('[AudioInterruption] Error starting interruption:', error);
      if (onError) {
        onError(error.message);
      }
      
      // Reset state
      setInterruptionState(prev => ({
        ...prev,
        isPreparing: false
      }));
      
      // Release the media stream on error
      completelyReleaseMedia();
      
      return false;
    }
  }, [startRecording, onError, completelyReleaseMedia]);
  
  // Process interruption (after recording stops)
  const processInterruption = useCallback(async () => {
    if (!recordedBlob) {
      console.warn('[AudioInterruption] No recorded audio to process');
      return;
    }
    
    // Track performance
    performanceMetricsRef.current.recordingEndTime = performance.now();
    performanceMetricsRef.current.processingStartTime = performance.now();
    
    // Create new abort controller for this request
    const controller = new AbortController();
    abortControllerRef.current = controller;
    
    try {
      // Update state (batch updates)
      setInterruptionState({
        isProcessing: true,
        processingStatus: 'Processing your interruption...',
        isPreparing: false
      });
      
      // Calculate and log recording time
      const recordingTime = performanceMetricsRef.current.recordingEndTime - 
                            performanceMetricsRef.current.recordingStartTime;
      console.log(`[AudioInterruption] Recording completed in ${recordingTime.toFixed(2)}ms`);
      
      // Use the optimized synchronous API
      const result = await interruptionService.processInterruption({
        channelId,
        contentId,
        userId,
        interruptedChapterNumber: chapterNumber,
        audioBlob: recordedBlob,
        signal: controller.signal
      });
      
      // Track performance
      performanceMetricsRef.current.processingEndTime = performance.now();
      const processingTime = performanceMetricsRef.current.processingEndTime - 
                             performanceMetricsRef.current.processingStartTime;
      
      console.log(`[AudioInterruption] Total processing time: ${processingTime.toFixed(2)}ms`);
      console.log('[AudioInterruption] Processing completed:', result);
      
      // Store audio-related properties in a single update to reduce renders
      setResponseAudio({
        element: result.audioElement, 
        url: result.audioUrl,
        blob: result.audioBlob,
        duration: result.audioDuration
      });
      
      // Update processing status
      setInterruptionState(prev => ({
        ...prev,
        processingStatus: 'Ready to play',
        isProcessing: false
      }));
      
      // Notify via callback
      if (onInterruptionComplete) {
        // Pass both the element and URL to ensure the player has access to both
        onInterruptionComplete(result.audioElement, result.audioUrl);
      }
    } catch (error) {
      console.error('[AudioInterruption] Error processing interruption:', error);
      
      if (error.name === 'AbortError' || abortControllerRef.current?.signal.aborted) {
        console.log('[AudioInterruption] Processing was aborted');
        return;
      }
      
      if (onError) {
        onError(`Error processing interruption: ${error.message}`);
      }
      
      // Reset processing state
      setInterruptionState(prev => ({
        ...prev,
        isProcessing: false
      }));
      
      // IMPORTANT FIX: Clear the recordedBlob to prevent retry loop
      resetRecorder();
      
      // Set a dummy responseAudio to break the processing loop
      setResponseAudio({ error: true });
    } finally {
      abortControllerRef.current = null;
    }
  }, [
    recordedBlob,
    channelId,
    contentId,
    userId,
    chapterNumber,
    onInterruptionComplete,
    onError,
    resetRecorder
  ]);
  
  // Effect to automatically process interruption when recording stops
  useEffect(() => {
    // When recording stops and we have a blob, process it
    if (!isRecording && recordedBlob && !isProcessing && !responseAudio) {
      processInterruption();
    }
  }, [isRecording, recordedBlob, isProcessing, responseAudio, processInterruption]);
  
  // Cleanup on unmount
  useEffect(() => {
    return () => {
      // Release object URLs - do this last to avoid premature cleanup
      if (responseAudio && responseAudio.url) {
        URL.revokeObjectURL(responseAudio.url);
      }
      
      // Abort any in-progress requests
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      
      // Make sure we completely release the media stream
      completelyReleaseMedia();
    };
  }, [responseAudio, completelyReleaseMedia]);
  
  return {
    // State
    isRecording,
    recordingDuration,
    hasPermission,
    isProcessing,
    processingStatus,
    isPreparing,
    responseAudio,
    error: recorderError,
    
    // Methods
    startInterruption,
    stopRecording,
    requestMicrophoneAccess,
    checkPermissionStatus,
    reset,
    completelyReleaseMedia, // Export this method for external use
  };
};

export default useAudioInterruption;