// src/utils/contentValidation.js
import { shouldBypassValidation } from '../config/testAccounts';

/**
 * Validates content form data
 * @param {Object} formData - Content form data to validate
 * @param {File|null} thumbnail - Thumbnail file
 * @param {string} userEmail - Current user's email (for test account detection)
 * @returns {Object} - Object with validation errors, empty if no errors
 */
export const validateContentForm = (formData, thumbnail, userEmail = null) => {
  const errors = {};
  const bypassLengthValidation = shouldBypassValidation(userEmail);
  
  // Script title validation
  if (!formData.script_title.trim()) {
    errors.script_title = "Title is required";
  } else if (!bypassLengthValidation) {
    if (formData.script_title.trim().length < 3) {
      errors.script_title = "Title must be at least 3 characters";
    } else if (formData.script_title.trim().length > 40) {
      errors.script_title = "Title must not exceed 40 characters";
    }
  }
  
  // Content description validation
  if (!formData.content_description?.trim()) {
    errors.content_description = "Content description is required";
  } else if (!bypassLengthValidation) {
    if (formData.content_description.trim().length < 300) {
      errors.content_description = "Content description must be at least 300 characters";
    } else if (formData.content_description.trim().length > 2500) {
      errors.content_description = "Content description must not exceed 2,500 characters";
    }
  }
  
  // Original script validation
  if (!formData.original_script.trim()) {
    errors.original_script = "Script is required";
  } else if (!bypassLengthValidation) {
    if (formData.original_script.trim().length < 1000) {
      errors.original_script = "Script must be at least 1,000 characters";
    } else if (formData.original_script.trim().length > 10000) {
      errors.original_script = "Script must not exceed 10,000 characters";
    }
  }
  
  // Content knowledge bank validation
  if (!formData.content_knowledge_bank.trim()) {
    errors.content_knowledge_bank = "Knowledge bank is required";
  } else if (!bypassLengthValidation) {
    if (formData.content_knowledge_bank.trim().length < 300) {
      errors.content_knowledge_bank = "Knowledge bank must be at least 300 characters";
    } else if (formData.content_knowledge_bank.trim().length > 5000) {
      errors.content_knowledge_bank = "Knowledge bank must not exceed 5,000 characters";
    }
  }
  
  // Thumbnail validation
  if (!thumbnail) {
    errors.thumbnail = "Thumbnail image is required";
  }
  
  return errors;
};

/**
 * Validates a thumbnail file
 * @param {File} file - The thumbnail file to validate
 * @returns {Object} - Object with validation error or null if valid
 */
export const validateThumbnail = (file) => {
  if (!file) {
    return { thumbnail: "Thumbnail image is required" };
  }

  // Check file type
  if (!file.type.match('image/jpeg') && !file.type.match('image/png')) {
    return { thumbnail: "Thumbnail must be JPG or PNG format" };
  }
  

};