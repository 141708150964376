// src/config/testAccounts/utils.js
import { testAccounts } from './accounts';

// Global toggle for bypassing validation - set to true by default
let bypassEnabled = true;






/**
 * Check if an email is in the test accounts list
 * @param {string} email - Email to check
 * @returns {boolean} - True if email is in test accounts list
 */
export const isTestAccount = (email) => {
  if (!email) return false;
  return testAccounts.includes(email.toLowerCase());
};

/**
 * Check if validation should be bypassed for the given email
 * @param {string} email - Email to check
 * @returns {boolean} - True if validation should be bypassed
 */
export const shouldBypassValidation = (email) => {
  return bypassEnabled && isTestAccount(email);
};

/**
 * Enable or disable validation bypass for test accounts
 * @param {boolean} enabled - Whether validation bypass is enabled
 */
export const setBypassEnabled = (enabled) => {
  bypassEnabled = enabled;
};

/**
 * Get current bypass enabled state
 * @returns {boolean} - Whether validation bypass is currently enabled
 */
export const isBypassEnabled = () => {
  return bypassEnabled;
};