// src/hooks/useAudioCache.js
import { useState, useEffect, useRef } from 'react';
import { getAudioUrl } from '../services/storageService';

/**
 * Custom hook for caching and managing audio files in strict sequential order
 * @param {Object} sessionDetails - The session details containing audio URLs
 * @returns {Object} Audio caching state and control methods
 */
const useAudioCache = (sessionDetails) => {
  // Caching state
  const [isLoading, setIsLoading] = useState(true);
  const [loadingChapter, setLoadingChapter] = useState(1);
  const [cacheComplete, setCacheComplete] = useState(false);
  const [error, setError] = useState(null);
  
  // Cache for both original and enriched audio
  const originalCache = useRef(new Map());
  const enrichedCache = useRef(new Map());
  
  // Total number of chapters to cache
  const [totalChapters, setTotalChapters] = useState(0);
  
  // Debug output state
  const [debugInfo, setDebugInfo] = useState({});

  // Process session details and cache audio files
  useEffect(() => {
    let mounted = true;
    let processedCount = 0;
    
    const cacheAudioChapters = async () => {
      if (!sessionDetails) {
        console.log("[AudioCache] No session details provided");
        return;
      }
      

      
      try {
        // Check if gcs_audio_urls exists
        if (!sessionDetails.gcs_audio_urls) {
          console.warn("[AudioCache] No gcs_audio_urls found in session details");
          
          // Update debug info
          setDebugInfo({
            hasSessionDetails: !!sessionDetails,
            hasGcsAudioUrls: false,
            sessionDetailsKeys: Object.keys(sessionDetails || {}),
          });
          
          setIsLoading(false);
          setCacheComplete(false);
          return;
        }
        
        // Get original and enriched audio maps
        const { original = {}, enriched = {} } = sessionDetails.gcs_audio_urls;
        
       
        
        // Get the keys
        const originalKeys = Object.keys(original);
        const enrichedKeys = Object.keys(enriched);
        
       
        
        // Count chapters
        const originalChapters = originalKeys.length;
        const enrichedChapters = enrichedKeys.length;
        
 
        
        // Max chapters
        const maxChapters = Math.max(originalChapters, enrichedChapters);
        
        if (!mounted) return;
        setTotalChapters(maxChapters);
        
        // Update debug info
        setDebugInfo({
          hasSessionDetails: !!sessionDetails,
          hasGcsAudioUrls: !!sessionDetails.gcs_audio_urls,
          originalChapters,
          enrichedChapters,
          originalKeys,
          enrichedKeys,
          originalSample: originalChapters > 0 ? original[originalKeys[0]] : null,
          enrichedSample: enrichedChapters > 0 ? enriched[enrichedKeys[0]] : null
        });
        
        // If no chapters, exit
        if (maxChapters === 0) {
          console.warn("[AudioCache] No audio chapters found in session details");
          if (mounted) {
            setIsLoading(false);
            setCacheComplete(false);
          }
          return;
        }
        
        // Process chapters sequentially
        for (let chapterNum = 1; chapterNum <= maxChapters; chapterNum++) {
          if (!mounted) return;
          
          // Update loading state
          setLoadingChapter(chapterNum);
          
          // Get the chapter key as string
          const chapterKey = chapterNum.toString();
          
          
          // Try different access methods to get the URLs
          const originalUrl = original[chapterKey] || null;
          const enrichedUrl = enriched[chapterKey] || null;
          

          
          // If both missing, skip
          if (!originalUrl && !enrichedUrl) {
            console.warn(`[AudioCache] No URLs found for chapter ${chapterKey}`);
            continue;
          }
          
          // Process original URL
          if (originalUrl) {
            try {
              // For direct Google Storage URLs, no processing needed
              const audioUrl = originalUrl.startsWith("https://storage.googleapis.com/") 
                ? originalUrl
                : await getAudioUrl(originalUrl);
                
              if (audioUrl && mounted) {
                originalCache.current.set(parseInt(chapterKey), audioUrl);
                processedCount++;
              } else {
                console.warn(`[AudioCache] Failed to resolve original URL for chapter ${chapterKey}`);
              }
            } catch (err) {
              console.error(`[AudioCache] Error caching original chapter ${chapterKey}:`, err);
            }
          }
          
          // Process enriched URL
          if (enrichedUrl) {
            try {
              // For direct Google Storage URLs, no processing needed
              const audioUrl = enrichedUrl.startsWith("https://storage.googleapis.com/") 
                ? enrichedUrl
                : await getAudioUrl(enrichedUrl);
                
              if (audioUrl && mounted) {
                enrichedCache.current.set(parseInt(chapterKey), audioUrl);
                processedCount++;
              } else {
                console.warn(`[AudioCache] Failed to resolve enriched URL for chapter ${chapterKey}`);
              }
            } catch (err) {
              console.error(`[AudioCache] Error caching enriched chapter ${chapterKey}:`, err);
            }
          }
        }
        
        // Complete caching
        if (mounted) {
          setCacheComplete(true);
          setIsLoading(false);
        }
      } catch (err) {
        console.error("[AudioCache] Error processing audio chapters:", err);
        if (mounted) {
          setError(`Failed to process audio chapters: ${err.message}`);
          setIsLoading(false);
        }
      }
    };
    
    cacheAudioChapters();
    
    return () => {
      mounted = false;
    };
  }, [sessionDetails]);
  
  /**
   * Get the cached URL for a specific chapter and type
   * @param {number} chapter - The chapter number
   * @param {string} type - Either 'original' or 'enriched'
   * @returns {string|null} - The cached URL or null if not cached
   */
  const getCachedUrl = (chapter, type) => {
    const cache = type === 'original' ? originalCache.current : enrichedCache.current;
    const url = cache.get(chapter);
    return url || null;
  };
  
  /**
   * Check if a specific chapter is cached
   * @param {number} chapter - The chapter number
   * @param {string} type - Either 'original' or 'enriched'
   * @returns {boolean} - True if cached, false otherwise
   */
  const isChapterCached = (chapter, type) => {
    const result = getCachedUrl(chapter, type) !== null;
    return result;
  };
  
  /**
   * Check if a chapter is ready for playback (both original and enriched are cached)
   * @param {number} chapter - The chapter number
   * @returns {boolean} - True if ready, false otherwise
   */
  const isChapterReady = (chapter) => {
    const originalReady = isChapterCached(chapter, 'original');
    const enrichedReady = isChapterCached(chapter, 'enriched');
    const isReady = originalReady && enrichedReady;
    
    return isReady;
  };
  
  return {
    isLoading,
    loadingChapter,
    cacheComplete,
    error,
    totalChapters,
    getCachedUrl,
    isChapterCached,
    isChapterReady,
    debugInfo
  };
};

export default useAudioCache;