// src/pages/SignInPage.jsx
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/common/Button';
import useAuthStore from '../store/authStore';
import { signInWithEmail, resetPassword } from '../services/authService';
import LoadingScreen from '../components/common/LoadingScreen';

const SignInPage = () => {
  const navigate = useNavigate();
  const { user, loading } = useAuthStore();
  const [signingIn, setSigningIn] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [resetMode, setResetMode] = useState(false);
  const [resetSent, setResetSent] = useState(false);

  // If user is already authenticated, redirect to feed
  useEffect(() => {
    if (user && !loading) {
      navigate('/feed');
    }
  }, [user, loading, navigate]);

  // Email sign-in handler
  const handleSignIn = async (e) => {
    e.preventDefault();
    setSigningIn(true);
    setError(null);
    
    try {
      const user = await signInWithEmail(email, password);
      
      if (!user.emailVerified) {
        setError('Please verify your email address before signing in.');
        setSigningIn(false);
        
        // Redirect to verification required page
        navigate('/verification-required');
        return;
      }
      
      // Navigation happens automatically via useEffect when user state updates
    } catch (error) {
      console.error('Sign-in error:', error);
      let errorMessage = 'Failed to sign in. Please check your credentials and try again.';
      
      // Handle specific Firebase error codes
      if (error.code === 'auth/user-not-found') {
        errorMessage = 'No account found with this email. Please sign up first.';
      } else if (error.code === 'auth/wrong-password') {
        errorMessage = 'Incorrect password. Please try again.';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Please enter a valid email address.';
      } else if (error.code === 'auth/too-many-requests') {
        errorMessage = 'Too many unsuccessful login attempts. Please try again later.';
      }
      
      setError(errorMessage);
    } finally {
      setSigningIn(false);
    }
  };

  // Password reset handler
  const handleResetPassword = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setError('Please enter your email address to reset your password.');
      return;
    }
    
    setSigningIn(true);
    setError(null);
    
    try {
      await resetPassword(email);
      setResetSent(true);
    } catch (error) {
      console.error('Password reset error:', error);
      let errorMessage = 'Failed to send password reset email. Please try again.';
      
      if (error.code === 'auth/user-not-found') {
        errorMessage = 'No account found with this email.';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Please enter a valid email address.';
      }
      
      setError(errorMessage);
    } finally {
      setSigningIn(false);
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen bg-yt-black flex items-center justify-center p-4">
      <div className="bg-yt-darker rounded-lg shadow-xl max-w-md w-full p-8 animate-fade-in">
        <div className="text-center mb-8">
          <Link to="/" className="inline-block">
            <h1 className="text-3xl font-bold">
              <span className="text-yt-red">Meta</span>
              <span className="text-white">Blogger</span>
            </h1>
          </Link>
          <p className="text-yt-text-secondary mt-2">
            {resetMode ? 'Reset your password' : 'Sign in to continue to MetaBlogger'}
          </p>
        </div>

        {error && (
          <div className="bg-red-900 bg-opacity-30 border border-red-800 text-white p-3 rounded-md mb-6">
            {error}
          </div>
        )}

        {resetMode ? (
          // Password Reset Form
          <>
            {resetSent ? (
              <div className="bg-green-900 bg-opacity-30 border border-green-800 text-white p-3 rounded-md mb-6">
                <p>Password reset email sent. Please check your inbox and follow the instructions.</p>
              </div>
            ) : (
              <form onSubmit={handleResetPassword} className="space-y-6">
                <div>
                  <label className="block text-yt-text-secondary mb-2" htmlFor="reset-email">
                    Email
                  </label>
                  <input
                    id="reset-email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full bg-yt-light text-white px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-yt-red"
                    required
                  />
                </div>

                <Button
                  type="submit"
                  variant="primary"
                  fullWidth
                  className="py-3"
                  disabled={signingIn}
                >
                  {signingIn ? 'Sending...' : 'Send Reset Link'}
                </Button>
              </form>
            )}

            <div className="mt-4 text-center">
              <button
                onClick={() => {
                  setResetMode(false);
                  setResetSent(false);
                  setError(null);
                }}
                className="text-blue-400 hover:underline text-sm"
              >
                Back to Sign In
              </button>
            </div>
          </>
        ) : (
          // Sign In Form
          <form onSubmit={handleSignIn} className="space-y-6">
            <div>
              <label className="block text-yt-text-secondary mb-2" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full bg-yt-light text-white px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-yt-red"
                required
              />
            </div>
            
            <div>
              <label className="block text-yt-text-secondary mb-2" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full bg-yt-light text-white px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-yt-red"
                required
              />
              <div className="text-right mt-1">
                <button
                  type="button"
                  onClick={() => {
                    setResetMode(true);
                    setError(null);
                  }}
                  className="text-blue-400 hover:underline text-sm"
                >
                  Forgot password?
                </button>
              </div>
            </div>

            <Button
              type="submit"
              variant="primary"
              fullWidth
              className="py-3"
              disabled={signingIn}
            >
              {signingIn ? 'Signing in...' : 'Sign In'}
            </Button>

            <div className="text-center text-sm text-yt-text-secondary">
              <p>By continuing, you agree to MetaBlogger's Terms of Service and Privacy Policy.</p>
            </div>
          </form>
        )}

        <div className="mt-8 pt-6 border-t border-yt-light text-center">
          <p className="text-sm text-yt-text-secondary">
            {resetMode ? (
              <span>Remember your password? <button onClick={() => setResetMode(false)} className="text-blue-400 hover:underline">Sign in</button></span>
            ) : (
              <>
                Don't have an account?{' '}
                <Link to="/signup" className="text-blue-400 hover:underline">
                  Sign up
                </Link>
              </>
            )}
          </p>
          <p className="text-sm text-yt-text-secondary mt-4">
            <Link to="/" className="text-blue-400 hover:underline">
              Return to home
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;