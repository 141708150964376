// src/components/creator/CreateChannelModal.jsx
import React, { useState } from "react";
import { FiX } from "react-icons/fi";
import useAuthStore from "../../store/authStore";
import Button from "../common/Button";
import { createChannel } from "../../services/channelService";
import { validateChannelForm } from "../../utils/channelValidation";

const CreateChannelModal = ({ onClose, onChannelCreated }) => {
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    channel_title: "",
    voice_id: "",
    channel_description: "",
    channel_knowledge_bank: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    
    // Clear error when field is updated
    if (formErrors[name]) {
      setFormErrors((prev) => ({ ...prev, [name]: null }));
    }
  };

  const validateForm = () => {
    const errors = validateChannelForm(formData, user?.email);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      // Create channel with the form data
      const channelData = {
        ...formData,
        user_id: user.uid,
      };
      
      const response = await createChannel(channelData);
      
      if (response.success) {
        // Call the success callback with the created channel
        onChannelCreated(response.data);
      } else {
        setError(response.message || "Failed to create channel");
      }
    } catch (err) {
      setError(err.message || "An unexpected error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4 animate-fade-in">
      <div className="bg-yt-darker rounded-lg max-w-2xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-4 border-b border-yt-light">
          <h2 className="text-xl font-bold text-yt-text">Create New Channel</h2>
          <button
            onClick={onClose}
            className="text-yt-text-secondary hover:text-yt-text p-2 rounded-full hover:bg-yt-light"
          >
            <FiX size={24} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="p-6">
          {error && (
            <div className="bg-red-900 bg-opacity-30 border border-red-800 text-white p-3 rounded-md mb-4">
              {error}
            </div>
          )}

          <div className="mb-4">
            <label className="block text-yt-text font-medium mb-2">
              Channel Title*
            </label>
            <input
              type="text"
              name="channel_title"
              value={formData.channel_title}
              onChange={handleChange}
              className={`w-full bg-yt-black text-yt-text rounded-md p-3 border ${
                formErrors.channel_title ? "border-red-500" : "border-yt-light"
              }`}
              placeholder="Give your channel a name"
            />
            {formErrors.channel_title && (
              <p className="text-red-500 text-sm mt-1">{formErrors.channel_title}</p>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-yt-text font-medium mb-2">
              Voice ID*
            </label>
            <input
              type="text"
              name="voice_id"
              value={formData.voice_id}
              onChange={handleChange}
              className={`w-full bg-yt-black text-yt-text rounded-md p-3 border ${
                formErrors.voice_id ? "border-red-500" : "border-yt-light"
              }`}
              placeholder="Enter the ElevenLabs Voice ID for your channel character"
            />
            {formErrors.voice_id && (
              <p className="text-red-500 text-sm mt-1">{formErrors.voice_id}</p>
            )}
            <p className="text-yt-text-secondary text-xs mt-1">
              Go to elevenlabs.io, choose your preferred voice, and copy its Voice ID. Paste it here — this voice will be used throughout your channel's Sessions.
            </p>
          </div>

          <div className="mb-4">
            <label className="block text-yt-text font-medium mb-2">
              Channel Description*
            </label>
            <textarea
              name="channel_description"
              value={formData.channel_description}
              onChange={handleChange}
              className={`w-full bg-yt-black text-yt-text rounded-md p-3 border ${
                formErrors.channel_description ? "border-red-500" : "border-yt-light"
              } h-32`}
              placeholder="Describe your channel (minimum 300 characters). This description will be visible to the public; however, it will not be stored in your AI Knowledge Bank."
            ></textarea>
            {formErrors.channel_description && (
              <p className="text-red-500 text-sm mt-1">{formErrors.channel_description}</p>
            )}
            <p className="text-yt-text-secondary text-xs mt-1 text-right">
              characters: {formData.channel_description.length}/2500
            </p>
          </div>

          <div className="mb-6">
            <label className="block text-yt-text font-medium mb-2">
              AI Knowledge Bank*
            </label>
            <textarea
              name="channel_knowledge_bank"
              value={formData.channel_knowledge_bank}
              onChange={handleChange}
              className={`w-full bg-yt-black text-yt-text rounded-md p-3 border ${
                formErrors.channel_knowledge_bank ? "border-red-500" : "border-yt-light"
              } h-48`}
              placeholder="Create an AI Knowledge Bank for your channel (minimum 600 characters). The AI Knowledge Bank isn't visible to the public. This will be used across all your sessions to improve AI responses in interactive mode. For each session, you'll create a separate AI Knowledge Bank, which will be relevant only for that session, but the channel AI Knowledge Bank will be used globally. You can include some information about your character's background, interests, stories, traits, speech style, etc. Try to be concise and clear."
            ></textarea>
            {formErrors.channel_knowledge_bank && (
              <p className="text-red-500 text-sm mt-1">{formErrors.channel_knowledge_bank}</p>
            )}
            <p className="text-yt-text-secondary text-xs mt-1 text-right">
              characters: {formData.channel_knowledge_bank.length}/2500
            </p>

          </div>

          <div className="flex justify-end">
            <Button
              variant="secondary"
              type="button"
              onClick={onClose}
              className="mr-3"
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? "Creating..." : "Create Channel"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateChannelModal;