// src/pages/ChannelPage.jsx
import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchChannel } from "../services/channelService";
import useContent from "../hooks/useContent";
import ContentDisplay from "../components/content/ContentDisplay";
import LoadingScreen from "../components/common/LoadingScreen";

const ChannelPage = () => {
  const { channelId } = useParams();
  
  // Fetch channel details
  const {
    data: channel,
    isLoading: channelLoading,
    error: channelError,
  } = useQuery(
    ["channel", channelId],
    () => fetchChannel(channelId),
    { enabled: !!channelId }
  );
  
  // Use the custom hook for content fetching with channelId
  const { 
    content, 
    isLoading: contentLoading, 
    error: contentError, 
    clearError, 
    refetch 
  } = useContent({ 
    channelId, 
    channelTitle: channel?.channel_title 
  });
  
  // Show loading screen while channel details are loading
  if (channelLoading) {
    return <LoadingScreen />;
  }
  
  // Combine errors
  const error = channelError || contentError;
  
  return (
    <div className="animate-fade-in">
      {/* Channel Header */}
      <div className="mb-6 bg-yt-darker rounded-lg p-6">
        <h1 className="text-2xl font-bold text-yt-text mb-2">
          {channel?.channel_title || "Channel"}
        </h1>
        <p className="text-yt-text-secondary">
          {channel?.channel_description || "No description available"}
        </p>
      </div>
      
      {/* Channel Content */}
      <div>
        <h2 className="text-xl font-medium text-yt-text mb-4">
          Channel Sessions
        </h2>
        <ContentDisplay
          content={content}
          isLoading={contentLoading}
          error={error}
          clearError={clearError}
          refetch={refetch}
          showRefreshButton={false}
        />
      </div>
    </div>
  );
};

export default ChannelPage;