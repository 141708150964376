// src/utils/channelValidation.js
import { shouldBypassValidation } from '../config/testAccounts';

/**
 * Validates channel form data
 * @param {Object} formData - Channel form data to validate
 * @param {string} userEmail - Current user's email (for test account detection)
 * @returns {Object} - Object with validation errors, empty if no errors
 */
export const validateChannelForm = (formData, userEmail = null) => {
  const errors = {};
  const bypassLengthValidation = shouldBypassValidation(userEmail);
  
  // Channel title validation
  if (!formData.channel_title.trim()) {
    errors.channel_title = "Channel title is required";
  } else if (!bypassLengthValidation) {
    if (formData.channel_title.trim().length < 3) {
      errors.channel_title = "Channel title must be at least 3 characters";
    } else if (formData.channel_title.trim().length > 40) {
      errors.channel_title = "Channel title must not exceed 40 characters";
    }
  }
  
  // Voice ID validation
  if (!formData.voice_id.trim()) {
    errors.voice_id = "Voice ID is required";
  }
  
  // Channel description validation
  if (!formData.channel_description.trim()) {
    errors.channel_description = "Channel description is required";
  } else if (!bypassLengthValidation) {
    if (formData.channel_description.trim().length < 300) {
      errors.channel_description = "Channel description must be at least 300 characters";
    } else if (formData.channel_description.trim().length > 2500) {
      errors.channel_description = "Channel description must not exceed 2,500 characters";
    }
  }
  
  // Knowledge bank validation
  if (!formData.channel_knowledge_bank.trim()) {
    errors.channel_knowledge_bank = "Channel knowledge bank is required";
  } else if (!bypassLengthValidation) {
    if (formData.channel_knowledge_bank.trim().length < 600) {
      errors.channel_knowledge_bank = "Channel knowledge bank must be at least 600 characters";
    } else if (formData.channel_knowledge_bank.trim().length > 2500) {
      errors.channel_knowledge_bank = "Channel knowledge bank must not exceed 2,500 characters";
    }
  }
  
  return errors;
};