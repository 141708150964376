import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from './Header';
import Sidebar from './Sidebar';
import useUiStore from '../../store/uiStore';
import useMediaQuery from '../../hooks/useMediaQuery';

const MainLayout = () => {
  const location = useLocation();
  const { sidebarOpen, setSidebarOpen, setActivePage } = useUiStore();
  const isMobile = useMediaQuery('(max-width: 768px)');
  
  // Set active page based on current route
  useEffect(() => {
    const path = location.pathname.split('/')[1] || 'home';
    setActivePage(path);
    
    // Close sidebar automatically on mobile
    if (isMobile) {
      setSidebarOpen(false);
    } else {
      setSidebarOpen(true);
    }
  }, [location, isMobile, setActivePage, setSidebarOpen]);

  return (
    <div className="flex h-screen bg-yt-black">
      <Sidebar />
      
      <div 
        className={`
          flex flex-col flex-grow transition-all duration-300
          ${sidebarOpen ? 'ml-60' : 'ml-20'}
        `}
      >
        <Header />
        
        <main className="flex-grow pt-14 overflow-auto">
          <div className="animate-fade-in container mx-auto p-4 max-w-7xl">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default MainLayout;