// src/components/audio/AudioPlayer.jsx
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { FiLoader, FiInfo, FiAlertTriangle, FiPause, FiPlay, FiMic } from 'react-icons/fi';
import Button from '../common/Button';
import TalkButton from './TalkButton';
import useAudioCache from '../../hooks/useAudioCache';
import useAudioInterruption from './AudioInterruption';
import { auth } from '../../services/firebaseConfig';

/**
 * Audio player component optimized for minimal latency
 * Enhanced with voice interruption capabilities and pause/continue feature
 */
const AudioPlayer = ({ sessionId, sessionDetails }) => {
  // Reference for pre-allocated audio elements to avoid creation costs
  const audioElementsRef = useRef({
    main: new Audio(),
    interruption: new Audio()
  });
  
  // Combined player state to reduce render cycles
  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    isPaused: false,
    currentChapter: 1,
    currentType: 'original',
    progress: 0,
    duration: 0,
  });
  
  // Extract individual states for cleaner code
  const { isPlaying, isPaused, currentChapter, currentType, progress, duration } = playerState;
  
  // UI states
  const [playerReady, setPlayerReady] = useState(false);
  const [debugMode, setDebugMode] = useState(false);
  const [audioError, setAudioError] = useState(null);
  const [micPermissionStatus, setMicPermissionStatus] = useState('checking'); // 'checking', 'granted', 'denied', 'prompt'
  const [isCheckingPermission, setIsCheckingPermission] = useState(true);
  
  // Timer and cleanup refs
  const progressIntervalRef = useRef(null);
  const responseUrlRef = useRef(null);
  
  // Performance tracking
  const performanceMetricsRef = useRef({
    playbackStartTimes: {},
    interruptionStartTime: 0
  });
  
  // Use the audio cache hook
  const { 
    isLoading: isCaching,
    loadingChapter,
    cacheComplete,
    error: cacheError,
    totalChapters,
    getCachedUrl,
    isChapterReady,
    debugInfo
  } = useAudioCache(sessionDetails);
  
  // Get user and channel info
  const user = auth.currentUser;
  const userId = user?.uid || 'anonymous';
  const channelId = sessionDetails?.channel_id || '';
  
  // Audio interruption hook
  const {
    isRecording,
    isProcessing,
    processingStatus,
    isPreparing,
    responseAudio,
    error: interruptionError,
    hasPermission,
    startInterruption,
    stopRecording,
    requestMicrophoneAccess,
    checkPermissionStatus,
    reset: resetInterruption,
    completelyReleaseMedia // New function to completely release media stream
  } = useAudioInterruption({
    userId,
    channelId,
    contentId: sessionId,
    chapterNumber: currentChapter,
    onInterruptionComplete: (audioElement, audioUrl) => {
      console.log('[AudioPlayer] Interruption processing complete, audio ready to play');
      responseUrlRef.current = audioUrl;
      
      // Track performance
      const processingTime = performance.now() - performanceMetricsRef.current.interruptionStartTime;
      console.log(`[AudioPlayer] Interruption processed in ${processingTime.toFixed(2)}ms`);
    },
    onError: (errorMsg) => {
      console.error('[AudioPlayer] Interruption error:', errorMsg);
      setAudioError(`Interruption error: ${errorMsg}`);
      
      // Continue with enriched audio as fallback
      if (isPlaying) {
        playChapter(currentChapter, 'enriched');
      }
    }
  });
  
  // Create memoized cleanup function to avoid recreating on every render
  const cleanupAudio = useCallback(() => {
    try {
      // Pause current audio playback
      const audio = audioElementsRef.current.main;
      if (audio) {
        audio.pause();
      }
      
      // Clear progress tracking
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
        progressIntervalRef.current = null;
      }
    } catch (e) {
      console.error('[AudioPlayer] Error cleaning up audio:', e);
    }
  }, []);
  
  /**
   * Reset player to initial state
   */
  const resetPlayer = useCallback(() => {
    cleanupAudio();
    
    // Reset player state
    setPlayerState({
      isPlaying: false,
      isPaused: false,
      currentChapter: 1,
      currentType: 'original',
      progress: 0,
      duration: 0,
    });
    
    // Clear any errors
    setAudioError(null);
    
    // Reset interruption state
    resetInterruption();
    
    // Release microphone
    completelyReleaseMedia();
    
    // Clear response URL if any
    if (responseUrlRef.current) {
      URL.revokeObjectURL(responseUrlRef.current);
      responseUrlRef.current = null;
    }
    
    console.log('[AudioPlayer] Player reset to initial state');
  }, [cleanupAudio, resetInterruption, completelyReleaseMedia]);
  
  /**
   * Explicitly request microphone permission
   * This will trigger the browser's permission dialog
   */
  const requestMicrophonePermission = useCallback(async () => {
    setAudioError(null);
    setMicPermissionStatus('checking');
    
    try {
      // This will trigger the browser's permission dialog
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      
      // Immediately release the stream since we don't need it yet
      stream.getTracks().forEach(track => track.stop());
      
      setMicPermissionStatus('granted');
    } catch (error) {
      console.error('[AudioPlayer] Microphone permission denied:', error);
      setMicPermissionStatus('denied');
      setAudioError('Microphone access is required. Please allow microphone access in your browser settings and refresh the page.');
    }
  }, []);
  
  /**
   * Optimized play chapter function with reduced latency
   */
  const playChapter = useCallback((chapter, type) => {
    const startTime = performance.now();
    performanceMetricsRef.current.playbackStartTimes[`${type}-${chapter}`] = startTime;
    
    // Reset error state
    setAudioError(null);
    
    console.log(`[AudioPlayer] Attempting to play ${type} chapter ${chapter}`);
    
    // Validate chapter number
    if (chapter < 1 || chapter > totalChapters) {
      console.warn(`[AudioPlayer] Invalid chapter number: ${chapter}`);
      
      // If we're trying to play a chapter beyond the last one, we've reached the end
      if (chapter > totalChapters) {
        console.log('[AudioPlayer] Reached the end of all chapters, resetting player');
        resetPlayer();
      }
      return;
    }
    
    // Check if the chapter is ready
    if (!isChapterReady(chapter)) {
      console.warn(`[AudioPlayer] Chapter ${chapter} is not ready for playback yet`);
      return;
    }
    
    // First clean up any existing audio playback
    cleanupAudio();
    
    // Get the cached URL
    const url = getCachedUrl(chapter, type);
    if (!url) {
      console.error(`[AudioPlayer] No URL found for ${type} chapter ${chapter}`);
      setAudioError(`Could not find audio URL for chapter ${chapter}`);
      return;
    }
    
    console.log(`[AudioPlayer] Playing ${type} chapter ${chapter} with URL: ${url.substring(0, 50)}...`);
    
    // Update player state in one batch update
    setPlayerState(prev => ({
      ...prev,
      currentChapter: chapter,
      currentType: type,
      isPaused: false,
      isPlaying: true,
    }));
    
    // Use the pre-allocated audio element
    const audio = audioElementsRef.current.main;
    
    // Set up event listeners first, then set src
    const setupAudio = () => {
      // Remove previous event listeners to prevent duplicates
      audio.onloadedmetadata = null;
      audio.onended = null;
      audio.onerror = null;
      
      // Set new event listeners
      audio.onloadedmetadata = () => {
        console.log(`[AudioPlayer] Audio metadata loaded, duration: ${audio.duration}`);
        setPlayerState(prev => ({
          ...prev,
          duration: audio.duration
        }));
        
        // Track performance
        const loadTime = performance.now() - startTime;
      };
      
      audio.onended = () => {
        console.log(`[AudioPlayer] Audio ended (${type} chapter ${chapter})`);
        
        // If this was an enriched chapter, move to the next original chapter
        if (type === 'enriched') {
          // Check if there's a next chapter before attempting to play it
          if (chapter < totalChapters) {
            playChapter(chapter + 1, 'original');
          } else {
            // This was the last enriched chapter, we're done
            console.log('[AudioPlayer] Finished final enriched chapter, resetting player');
            resetPlayer();
          }
        } else {
          // Original chapter ended
          // If this was the last chapter, stop playback
          if (chapter >= totalChapters) {
            console.log('[AudioPlayer] Reached last chapter, resetting player');
            resetPlayer();
          } else {
            // Otherwise, continue to the next chapter
            console.log(`[AudioPlayer] Moving to next chapter: ${chapter + 1}`);
            playChapter(chapter + 1, 'original');
          }
        }
      };
      
      audio.onerror = (e) => {
        // Check if the error happened because the component was unmounted
        if (!document.body.contains(audio)) {
          console.log('[AudioPlayer] Audio error after unmount, ignoring');
          return;
        }
        
        const errorCode = audio.error ? audio.error.code : 'unknown';
        const errorMessage = audio.error ? audio.error.message : 'unknown';
        
        console.error(`[AudioPlayer] Audio error: Code=${errorCode}, Message=${errorMessage}`);
        setAudioError(`Audio playback error: ${errorMessage}`);
        
        // Reset the player on error
        resetPlayer();
      };
      
      // Set the source and load
      audio.src = url;
      audio.crossOrigin = 'anonymous';
      audio.load();
      
      // Set up progress tracker
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
      
      progressIntervalRef.current = setInterval(() => {
        if (audio.currentTime) {
          setPlayerState(prev => ({
            ...prev,
            progress: audio.currentTime
          }));
        }
      }, 1000);
      
      // Start playback
      const playPromise = audio.play();
      
      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            console.log(`[AudioPlayer] Playback started for ${type} chapter ${chapter}`);
            
            // Update playing state
            setPlayerState(prev => ({
              ...prev,
              isPlaying: true
            }));
            
            // Track performance
            const playTime = performance.now() - startTime;
          })
          .catch(err => {
            // Don't update state if the component was unmounted
            if (!document.body.contains(audio)) {
              console.log('[AudioPlayer] Play error after unmount, ignoring');
              return;
            }
            
            console.error('[AudioPlayer] Error playing audio:', err);
            
            // Only update state if it's a real error, not just a user-initiated pause
            if (err.name !== 'AbortError') {
              resetPlayer();
              setAudioError(`Failed to play audio: ${err.message}`);
            }
          });
      }
    };
    
    // Run setup immediately
    setupAudio();
  }, [totalChapters, isChapterReady, getCachedUrl, cleanupAudio, resetPlayer]);
  
  /**
   * Launch session
   */
  const launchSession = useCallback(() => {
    if (micPermissionStatus !== 'granted') {
      console.warn('[AudioPlayer] Cannot launch session, microphone permission not granted');
      setAudioError('Microphone permission is required to launch a session');
      return;
    }
    
    if (!playerReady) {
      console.warn('[AudioPlayer] Cannot launch session, player not ready');
      return;
    }
    
    console.log('[AudioPlayer] Launching session');
    
    // Start with the first original chapter
    setAudioError(null);
    playChapter(1, 'original');
  }, [micPermissionStatus, playerReady, playChapter]);
  
  /**
   * Optimized method to play interruption response with minimal latency
   */
  const playInterruptionResponse = useCallback(() => {
    const startTime = performance.now();
    performanceMetricsRef.current.interruptionStartTime = startTime;
    
    // Check if we have a URL to play
    if (!responseUrlRef.current) {
      console.warn('[AudioPlayer] No interruption response URL available');
      // Continue with enriched audio as fallback
      playChapter(currentChapter, 'enriched');
      return;
    }
    
    // Clean up any current audio playback
    cleanupAudio();
    
    console.log('[AudioPlayer] Playing interruption response audio');
    
    // Use the pre-allocated interruption audio element
    const audio = audioElementsRef.current.interruption;
    
    // Remove previous event listeners
    audio.onended = null;
    audio.onerror = null;
    
    // Set up new event listeners
    audio.onended = () => {
      console.log('[AudioPlayer] Interruption response ended, continuing with enriched audio');
      
      // Track performance
      const playTime = performance.now() - startTime;
      console.log(`[AudioPlayer] Interruption played in ${playTime.toFixed(2)}ms`);
      
      // Continue with enriched audio
      playChapter(currentChapter, 'enriched');
    };
    
    audio.onerror = (e) => {
      const errorCode = audio.error ? audio.error.code : 'unknown';
      const errorMessage = audio.error ? audio.error.message : 'unknown';
      console.error(`[AudioPlayer] Error playing interruption response: Code=${errorCode}, Message=${errorMessage}`);
      
      // Continue with enriched audio as fallback
      playChapter(currentChapter, 'enriched');
    };
    
    // Set the source
    audio.src = responseUrlRef.current;
    audio.load();
    
    // Play the response
    const playPromise = audio.play();
    
    if (playPromise !== undefined) {
      playPromise.catch(err => {
        console.error('[AudioPlayer] Error playing interruption response:', err);
        
        // Continue with enriched audio as fallback if it's not just an abort
        if (err.name !== 'AbortError') {
          playChapter(currentChapter, 'enriched');
        }
      });
    }
  }, [currentChapter, playChapter, cleanupAudio]);
  
  // Request microphone permission immediately on component mount
  useEffect(() => {
    if (isCheckingPermission) {
      const checkPermissionOnly = async () => {
        try {
          console.log('[AudioPlayer] Checking microphone permission on component mount');
          setMicPermissionStatus('checking');
          
          // Just check the current permission status without prompting
          const status = await checkPermissionStatus();
          
          if (status === 'granted') {
            setMicPermissionStatus('granted');
            console.log('[AudioPlayer] Microphone permission already granted');
          } else if (status === 'denied') {
            setMicPermissionStatus('denied');
            console.log('[AudioPlayer] Microphone permission denied');
          } else {
            // Set to 'prompt' to show the grant permission button
            setMicPermissionStatus('prompt');
            console.log('[AudioPlayer] Microphone permission needs prompt');
          }
          
          setIsCheckingPermission(false);
        } catch (error) {
          console.error('[AudioPlayer] Error checking microphone permission:', error);
          setMicPermissionStatus('prompt'); // Default to prompt
          setIsCheckingPermission(false);
        }
      };
      
      checkPermissionOnly();
    }
  }, [isCheckingPermission, checkPermissionStatus]);
  
  // Initialize player when caching is complete
  useEffect(() => {
    if (cacheComplete && totalChapters > 0) {
      setPlayerReady(true);
    } else {
      setPlayerReady(false);
    }
  }, [cacheComplete, totalChapters]);
  
  // Effect to play interruption response when ready
  useEffect(() => {
    if (isPlaying && responseAudio && !isProcessing && !isPaused) {
      console.log('[AudioPlayer] Audio response ready, preparing to play');
      
      // Check if this is an error response (dummy object with error flag)
      if (responseAudio.error) {
        console.log('[AudioPlayer] Interruption resulted in error, skipping to enriched chapter');
        // Simply continue with enriched chapter if we had an error
        playChapter(currentChapter, 'enriched');
        // Clear interruption state
        resetInterruption();
        return;
      }
      
      // Normal success path - clear interruption state before playing
      resetInterruption();
      // Play the response with minimal delay
      playInterruptionResponse();
    }
  }, [isPlaying, responseAudio, isProcessing, resetInterruption, playInterruptionResponse, isPaused, playChapter, currentChapter]);
  
  // Cleanup on unmount
  useEffect(() => {
    return () => {
      // Clean up audio resources
      cleanupAudio();
      
      // Clean up URL references
      if (responseUrlRef.current) {
        try {
          URL.revokeObjectURL(responseUrlRef.current);
        } catch (e) {
          console.warn('[AudioPlayer] Error revoking URL:', e);
        }
        responseUrlRef.current = null;
      }
      
      // Make sure to release the microphone
      if (completelyReleaseMedia) {
        completelyReleaseMedia();
      }
    };
  }, [cleanupAudio, completelyReleaseMedia]);
  
  /**
   * Start talking (press and hold)
   */
  const handleTalkStart = useCallback(async () => {
    if (!isPlaying || isRecording || isProcessing || isPaused) return;
    
    console.log('[AudioPlayer] Talk button pressed, activating microphone');
    
    // Clear previous errors
    setAudioError(null);
    
    // Pause current audio
    const audio = audioElementsRef.current.main;
    if (audio) {
      audio.pause();
    }
    
    try {
      // NOW we actually request microphone access and activate it
      const accessGranted = await requestMicrophoneAccess();
      
      if (!accessGranted) {
        console.warn('[AudioPlayer] Microphone access failed');
        setAudioError('Failed to access microphone. Please try again.');
        
        // Resume playback if access failed
        if (audio) {
          audio.play().catch(err => {
            console.error('[AudioPlayer] Error resuming playback after access failure:', err);
          });
        }
        return;
      }
      
      console.log('[AudioPlayer] Microphone activated, starting interruption recording');
      performanceMetricsRef.current.interruptionStartTime = performance.now();
      
      // Start recording
      await startInterruption();
    } catch (error) {
      console.error('[AudioPlayer] Error during Talk button press:', error);
      setAudioError(`Talk feature error: ${error.message}`);
      
      // Resume playback on error
      if (audio) {
        audio.play().catch(err => {
          console.error('[AudioPlayer] Error resuming playback after error:', err);
        });
      }
    }
  }, [isPlaying, isRecording, isProcessing, isPaused, requestMicrophoneAccess, startInterruption]);
  
  /**
   * End talking (release)
   */
  const handleTalkEnd = useCallback(() => {
    if (!isRecording) return;
    
    console.log('[AudioPlayer] Talk button released, stopping recording');
    
    // Stop recording
    stopRecording();
    
    // Important: Release the microphone completely
    completelyReleaseMedia();
    
    // Handle errors immediately
    if (interruptionError) {
      console.warn('[AudioPlayer] Interruption error detected, switching to enriched chapter');
      playChapter(currentChapter, 'enriched');
    }
  }, [isRecording, stopRecording, interruptionError, playChapter, currentChapter, completelyReleaseMedia]);
  
  /**
   * Toggle pause/continue
   */
  const handlePauseToggle = useCallback(() => {
    if (isPaused) {
      // Continue playback - always play enriched chapter from beginning
      console.log('[AudioPlayer] Continuing playback with enriched chapter');
      playChapter(currentChapter, 'enriched');
    } else {
      // Pause current playback
      console.log('[AudioPlayer] Pausing playback');
      const audio = audioElementsRef.current.main;
      if (audio) {
        audio.pause();
      }
      
      // Update state
      setPlayerState(prev => ({
        ...prev,
        isPaused: true
      }));
    }
  }, [isPaused, currentChapter, playChapter]);
  
  /**
   * Format time in seconds to MM:SS format
   */
  const formatTime = useCallback((seconds) => {
    if (isNaN(seconds) || seconds === Infinity) return '00:00';
    
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  }, []);
  
  // Determine if we should show the cached indicator
  const showCachingIndicator = isCaching && loadingChapter <= totalChapters;
  
  // Toggle debug mode
  const toggleDebugMode = useCallback(() => {
    setDebugMode(prev => !prev);
  }, []);
  
  // Performance metrics for debug view - only calculated when needed
  const performanceInfo = useMemo(() => {
    return {
      interruptionTimes: {
        startTime: performanceMetricsRef.current.interruptionStartTime,
        currentTime: performance.now()
      },
      playbackStartTimes: performanceMetricsRef.current.playbackStartTimes
    };
  }, []); // No dependencies - we can use this directly and it will use latest refs
  
  return (
    <div className="bg-yt-black rounded-lg p-4 mt-4">
      {/* Audio Progress Bar */}
      <div className="mb-4">
        <div className="h-1 w-full bg-yt-light rounded overflow-hidden">
          <div 
            className="h-full bg-yt-red" 
            style={{ width: `${duration ? (progress / duration) * 100 : 0}%` }}
          ></div>
        </div>
        
        {/* Time display */}
        <div className="flex justify-between text-xs text-yt-text-secondary mt-1">
          <span>{formatTime(progress)}</span>
          <span>{formatTime(duration)}</span>
        </div>
      </div>
      
      {/* Microphone Permission Status */}
      {micPermissionStatus === 'checking' && (
        <div className="mb-4 p-3 bg-yt-darker rounded text-yt-text-secondary text-sm flex items-center justify-center">
          <FiLoader className="animate-spin mr-2" size={16} />
          <span>Checking microphone permission...</span>
        </div>
      )}
      
      
      {/* Control Buttons (only shown when playing) */}
      {isPlaying && (
        <div className="relative">
          <div className="flex space-x-2">
            {/* Talk Button */}
            <div className="flex-1">
              <TalkButton 
                onHoldStart={handleTalkStart}
                onHoldEnd={handleTalkEnd}
                disabled={!isPlaying || isProcessing || isPaused}
                isRecording={isRecording}
                compact={true}
              />
            </div>
            
            {/* Pause/Continue Button */}
            <div className="flex-1">
              <Button
                variant={isPaused ? "primary" : "secondary"}
                size="lg"
                fullWidth
                className="flex items-center justify-center"
                onClick={handlePauseToggle}
                disabled={isRecording || isProcessing}
                icon={isPaused ? <FiPlay size={20} /> : <FiPause size={20} />}
              >
                {isPaused ? "Continue" : "Pause"}
              </Button>
            </div>
          </div>
          
          {/* Processing indicator */}
          {isProcessing && (
            <div className="mt-2 text-center text-yt-text-secondary text-sm flex items-center justify-center">
              <FiLoader className="animate-spin mr-2" size={16} />
              <span>{processingStatus || 'Processing interruption...'}</span>
            </div>
          )}
        </div>
      )}
      
      {/* Launch Session Button (only shown when not playing) */}
      {!isPlaying && (
        <div className="mt-4">
          {micPermissionStatus !== 'granted' ? (
            <Button
              variant="secondary"
              size="lg"
              fullWidth
              onClick={requestMicrophonePermission}
              disabled={isCheckingPermission}
              icon={<FiMic size={20} />}
            >
              {isCheckingPermission 
                ? "Checking microphone permission..." 
                : "Grant Microphone Permission"
              }
            </Button>
          ) : (
            <Button
              variant="primary"
              size="lg"
              fullWidth
              onClick={launchSession}
              disabled={!playerReady}
            >
              {!playerReady
                ? "No Audio Available"
                : "Launch Session"
              }
            </Button>
          )}
          
          {!playerReady && totalChapters === 0 && micPermissionStatus === 'granted' && (
            <div className="mt-2 text-center text-yt-text-secondary text-sm">
              This session doesn't have any audio chapters
            </div>
          )}
        </div>
      )}
      
      {/* Audio error message */}
      {audioError && (
        <div className="mt-4 p-2 bg-red-900/50 rounded text-red-200 text-sm">
          {audioError}
        </div>
      )}
      
      {/* Caching Status */}
      {showCachingIndicator && (
        <div className="mt-4 flex items-center justify-center text-yt-text-secondary text-sm">
          <FiLoader className="animate-spin mr-2" size={16} />
          <span>Preparing audio {loadingChapter}/{totalChapters}</span>
        </div>
      )}
      
      {/* Player current status */}
      <div className="mt-4 flex justify-between items-center text-xs text-yt-text-secondary">
        <span>
          Chapter {currentChapter}/{totalChapters}
        </span>
        <span className="capitalize">
          {isRecording ? "Recording" : isPaused ? "Paused" : currentType} audio
        </span>
        
        {/* Debug toggle button */}
        <button 
          onClick={toggleDebugMode}
          className="text-yt-text-secondary hover:text-yt-red"
        >
          <FiInfo size={16} />
        </button>
      </div>
      
      {/* Error message */}
      {cacheError && (
        <div className="mt-4 text-red-500 text-sm">
          {cacheError}
        </div>
      )}
      
      {/* Debug information (hidden by default) */}
      {debugMode && (
        <div className="mt-4 p-2 bg-yt-light rounded text-xs text-yt-text-secondary whitespace-pre-wrap">
          <h4 className="font-bold mb-1">Debug Info:</h4>
          <div>Mic Permission: {micPermissionStatus}</div>
          <div>Player Ready: {playerReady ? 'Yes' : 'No'}</div>
          <div>Cache Complete: {cacheComplete ? 'Yes' : 'No'}</div>
          <div>Total Chapters: {totalChapters}</div>
          <div>Is Caching: {isCaching ? 'Yes' : 'No'}</div>
          <div>Current Chapter: {currentChapter}</div>
          <div>Current Type: {currentType}</div>
          <div>Chapter 1 Ready: {isChapterReady(1) ? 'Yes' : 'No'}</div>
          <div>Is Paused: {isPaused ? 'Yes' : 'No'}</div>
          <div>Audio Error: {audioError || 'None'}</div>
          <div>Is Recording: {isRecording ? 'Yes' : 'No'}</div>
          <div>Is Processing: {isProcessing ? 'Yes' : 'No'}</div>
          <div>Has Mic Permission: {hasPermission ? 'Yes' : 'No'}</div>
          
          <h4 className="font-bold mt-2 mb-1">Performance Metrics:</h4>
          <div>Interruption Start: {performanceMetricsRef.current.interruptionStartTime > 0 ? 
            `${(performance.now() - performanceMetricsRef.current.interruptionStartTime).toFixed(2)}ms ago` : 'N/A'}</div>
          
          <h4 className="font-bold mt-2 mb-1">Debug Details:</h4>
          <pre>{JSON.stringify({...debugInfo, performanceInfo}, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default AudioPlayer;