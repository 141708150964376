// src/services/contentService.js
import axios from 'axios';
import { 
  query, 
  collectionGroup, 
  where, 
  getDocs, 
  limit,
} from 'firebase/firestore';
import { db } from './firebaseConfig';

const API_BASE_URL = 'https://api.alindor.tech'; // Your API base URL

// Create axios instance with default config for JSON data
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Create axios instance with multipart form data config
const formApi = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

/**
 * Create new content with multipart form data
 * @param {FormData} formData - FormData containing content data and thumbnail file
 * @returns {Promise<Object>} Result of content creation
 */
export const createContent = async (formData) => {
  try {
    const response = await formApi.post('/api/content/', formData);
    
    // Return standardized response
    if (response.data.success) {
      return {
        success: true,
        message: response.data.message,
        content_id: response.data.content_id,
        task_id: response.data.task_id,
        thumbnail_path: response.data.thumbnail_path,
      };
    } else {
      return {
        success: false,
        message: response.data.message || 'Failed to create content',
        content_id: null,
        task_id: null,
        thumbnail_path: null,
      };
    }
  } catch (error) {
    console.error('Error creating content:', error);
    
    // Handle error response from API
    if (error.response?.data?.message) {
      return {
        success: false,
        message: error.response.data.message,
        content_id: null,
        task_id: null,
        thumbnail_path: null,
      };
    }
    
    // Handle network or other errors
    return {
      success: false,
      message: error.message || 'An error occurred while creating the content',
      content_id: null,
      task_id: null,
      thumbnail_path: null,
    };
  }
};

/**
 * Get content details including description
 * @param {string} contentId - Content ID
 * @returns {Promise<Object>} Content details
 */
export const fetchContentDetails = async (contentId) => {
  try {
    if (!contentId) {
      throw new Error('Content ID is required');
    }
    
    
    // Create a query against the content subcollections
    const contentQuery = query(
      collectionGroup(db, 'content'),
      where('content_id', '==', contentId),
      limit(1)
    );
    
    const querySnapshot = await getDocs(contentQuery);
    
    if (querySnapshot.empty) {
      console.warn(`[ContentService] No content found with ID ${contentId}`);
      throw new Error(`Content with ID ${contentId} not found`);
    }
    
    // Get the first matching document
    const contentDoc = querySnapshot.docs[0];
    const data = contentDoc.data();
    
    
    
    // Extract channel_id from path
    const pathParts = contentDoc.ref.path.split('/');
    const channelId = pathParts.length > 1 ? pathParts[1] : null;
    
    // Make a copy of gcs_audio_urls to ensure we don't have reference issues
    let audioUrls = null;
    if (data.gcs_audio_urls) {
      audioUrls = {
        original: {...(data.gcs_audio_urls.original || {})},
        enriched: {...(data.gcs_audio_urls.enriched || {})}
      };
    }
    
    // Create formatted content object
    const contentDetails = {
      content_id: contentId,
      script_title: data.script_title || data.title,
      title: data.title || data.script_title,
      content_description: data.content_description,
      content_knowledge_bank: data.content_knowledge_bank,
      original_script: data.original_script,
      channel_id: data.channel_id || channelId,
      channel_title: data.channel_title,
      thumbnail_path: data.thumbnail_path,
      status: data.status,
      created_at: data.created_at,
      view_count: data.view_count || 0,
      like_count: data.like_count || 0,
      duration: data.duration || null,
      gcs_audio_urls: audioUrls || { original: {}, enriched: {} }
    };
    
   
    
    return contentDetails;
  } catch (error) {
    console.error(`Error fetching content details for ${contentId}:`, error);
    throw error;
  }
};

/**
 * Get content knowledge bank
 * @param {string} contentId - Content ID
 * @returns {Promise<string>} Content knowledge bank
 */
export const fetchContentKnowledgeBank = async (contentId) => {
  try {
    const response = await api.get(`/api/content/${contentId}/content-knowledge-bank`);
    
    if (response.data.success) {
      return response.data.content_knowledge_bank;
    } else {
      throw new Error(response.data.message || 'Failed to fetch content knowledge bank');
    }
  } catch (error) {
    console.error(`Error fetching knowledge bank for content ${contentId}:`, error);
    throw error;
  }
};

/**
 * Get thumbnail URL for content
 * @param {string} contentId - Content ID
 * @returns {string} Thumbnail URL
 */
export const getContentThumbnailUrl = (contentId) => {
  return `${API_BASE_URL}/api/content/${contentId}/thumbnail`;
};