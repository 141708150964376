// src/components/auth/ProtectedRoute.jsx
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuthStore from '../../store/authStore';
import LoadingScreen from '../common/LoadingScreen';

const ProtectedRoute = () => {
  const { user, loading } = useAuthStore();

  // Show loading screen while checking authentication
  if (loading) {
    return <LoadingScreen />;
  }

  // Redirect to sign in if not authenticated
  if (!user) {
    return <Navigate to="/signin" replace />;
  }

  // Check if email is verified
  if (user && !user.emailVerified) {
    return <Navigate to="/signin" replace />;
  }

  // Render child routes
  return <Outlet />;
};

export default ProtectedRoute;