// src/services/interruptionService.js
import axios from 'axios';

// Base API URL
const API_BASE_URL = 'https://api.alindor.tech';

/**
 * Optimized service for handling the synchronous interruption API
 * with reduced latency and improved performance
 */
class InterruptionService {
  constructor() {
    // Pre-allocate reusable Audio element to avoid creation costs
    this.audioElement = null;
  }

  /**
   * Process an interruption with optimized performance
   * @param {Object} params - Interruption parameters
   * @param {string} params.channelId - Channel ID
   * @param {string} params.contentId - Content ID
   * @param {string} params.userId - User ID
   * @param {number} params.interruptedChapterNumber - Chapter number being interrupted
   * @param {Blob} params.audioBlob - Audio blob to send
   * @param {AbortSignal} params.signal - Optional abort signal
   * @returns {Promise<Object>} Optimized response with audio data
   */
  async processInterruption({
    channelId,
    contentId,
    userId,
    interruptedChapterNumber,
    audioBlob,
    signal
  }) {
    console.log('[InterruptionService] Processing interruption', {
      channelId,
      contentId,
      userId,
      interruptedChapterNumber,
      audioSize: audioBlob.size
    });

    try {
      // Create query parameters
      const queryParams = new URLSearchParams({
        channel_id: channelId,
        content_id: contentId,
        user_id: userId,
        interrupted_chapter_number: interruptedChapterNumber.toString(),
      });

      // Set up request configuration
      const config = {
        headers: {
          'Content-Type': 'audio/mp3',
        },
        responseType: 'blob', // Important: we want a binary response
        timeout: 20000, // 20 second timeout
        signal, // Support for abort controller
      };

      // Start API request timing
      const requestStartTime = performance.now();

      // Make the direct request to the synchronous endpoint
      console.log(`[InterruptionService] Sending request to ${API_BASE_URL}/interruptions/handle`);
      const response = await axios.post(
        `${API_BASE_URL}/interruptions/handle?${queryParams.toString()}`,
        audioBlob,
        config
      );

      // Calculate API request time
      const apiRequestTime = performance.now() - requestStartTime;
      console.log(`[InterruptionService] API request completed in ${apiRequestTime.toFixed(2)}ms`);

      // Extract headers containing metadata
      const audioDuration = parseFloat(response.headers['x-audio-duration'] || '0');
      const interruptionId = response.headers['x-interruption-id'] || 'unknown';
      const processingTime = parseFloat(response.headers['x-processing-time'] || '0');

      console.log('[InterruptionService] Response received', {
        status: response.status,
        contentType: response.headers['content-type'],
        contentLength: response.headers['content-length'],
        audioDuration,
        interruptionId,
        processingTime,
      });

      // Start blob processing timing
      const blobStartTime = performance.now();

      // Create a new blob from the response data with the correct MIME type
      const responseBlob = new Blob([response.data], { type: 'audio/mpeg' });
      const audioUrl = URL.createObjectURL(responseBlob);
      
      console.log(`[InterruptionService] Created audio blob: size=${responseBlob.size} bytes, type=${responseBlob.type}`);
      console.log(`[InterruptionService] Blob processing completed in ${(performance.now() - blobStartTime).toFixed(2)}ms`);

      // Create or reuse audio element - don't wait for loadedmetadata if we already have duration
      if (!this.audioElement) {
        this.audioElement = new Audio();
      }
      
      // Set the source on the pre-allocated element
      this.audioElement.src = audioUrl;
      
      // Return optimized response structure
      return {
        audioElement: this.audioElement,
        audioBlob: responseBlob,
        audioUrl,
        audioDuration, // Use the duration from headers
        interruptionId,
        processingTime,
        clientProcessingTime: performance.now() - requestStartTime
      };
    } catch (error) {
      console.error('[InterruptionService] Error processing interruption:', error);
      
      // Detailed error logging
      if (error.response) {
        console.error('[InterruptionService] Server error:', {
          status: error.response.status,
          statusText: error.response.statusText,
          data: error.response.data
        });
      } else if (error.request) {
        console.error('[InterruptionService] No response received (timeout or network error)');
      }
      
      throw new Error(`Failed to process interruption: ${error.message}`);
    }
  }
}

// Create and export a singleton instance
const interruptionService = new InterruptionService();
export default interruptionService;