import React, { useState, useEffect } from 'react';
import { FiAlertCircle } from 'react-icons/fi';

const ErrorMessage = ({ message, onClose }) => {
  const [visible, setVisible] = useState(true);
  
  useEffect(() => {
    if (onClose) {
      const timer = setTimeout(() => {
        setVisible(false);
        onClose();
      }, 5000);
      
      return () => clearTimeout(timer);
    }
  }, [onClose]);
  
  if (!visible) return null;
  
  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-red-900 text-white px-4 py-3 rounded-md shadow-lg flex items-center z-50">
      <FiAlertCircle className="mr-2" size={20} />
      <span>{message}</span>
      {onClose && (
        <button 
          className="ml-4 text-white"
          onClick={() => {
            setVisible(false);
            onClose();
          }}
        >
          ×
        </button>
      )}
    </div>
  );
};

export default ErrorMessage;