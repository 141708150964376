import { create } from 'zustand';

const useUiStore = create((set) => ({
  // Sidebar state
  sidebarOpen: true,
  toggleSidebar: () => set((state) => ({ sidebarOpen: !state.sidebarOpen })),
  setSidebarOpen: (isOpen) => set({ sidebarOpen: isOpen }),
  
  // Active page
  activePage: 'feed',
  setActivePage: (page) => set({ activePage: page }),
  
  // Search query
  searchQuery: '',
  setSearchQuery: (query) => set({ searchQuery: query }),
}));

export default useUiStore;