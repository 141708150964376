// src/components/audio/TalkButton.jsx
import React, { useState, useEffect, useCallback } from 'react';
import { FiMic } from 'react-icons/fi';
import Button from '../common/Button';

/**
 * Talk button component for pausing original audio and triggering voice recording
 */
const TalkButton = ({ 
  onHoldStart, 
  onHoldEnd,
  disabled = false,
  isRecording = false,
  compact = false // New prop to control size for side-by-side layout
}) => {
  const [isHolding, setIsHolding] = useState(false);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [timerInterval, setTimerInterval] = useState(null);
  const MAX_DURATION_MS = 120000; // 2 minutes in milliseconds
  
  // Handle button release (mouseup/touchend)
  const handleHoldEnd = useCallback((e) => {
    if (e) e.preventDefault();
    
    if (disabled || !isHolding) return;
    
    // Clear the timer
    if (timerInterval) {
      clearInterval(timerInterval);
      setTimerInterval(null);
    }
    
    setIsHolding(false);
    if (onHoldEnd) onHoldEnd();
  }, [onHoldEnd, isHolding, disabled, timerInterval]);

  // Handle button press (mousedown/touchstart)
  const handleHoldStart = useCallback((e) => {
    e.preventDefault(); // Prevent default to avoid text selection
    
    if (disabled) return;
    
    setIsHolding(true);
    setRecordingDuration(0);
    
    // Start timer for recording duration display
    const startTime = Date.now();
    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      setRecordingDuration(elapsedTime);
      
      // Auto-stop if we reach the maximum duration
      if (elapsedTime >= MAX_DURATION_MS) {
        handleHoldEnd(new Event('auto-release'));
      }
    }, 100);
    
    setTimerInterval(interval);
    
    if (onHoldStart) onHoldStart();
  }, [onHoldStart, disabled, handleHoldEnd, MAX_DURATION_MS]);
  
  // Add global event listeners to handle cases where mouse is released outside the button
  useEffect(() => {
    if (isHolding) {
      // Add global listeners
      document.addEventListener('mouseup', handleHoldEnd);
      document.addEventListener('touchend', handleHoldEnd);
      
      // Cleanup
      return () => {
        document.removeEventListener('mouseup', handleHoldEnd);
        document.removeEventListener('touchend', handleHoldEnd);
      };
    }
  }, [isHolding, handleHoldEnd]);
  
  // Clean up the timer on unmount
  useEffect(() => {
    return () => {
      if (timerInterval) {
        clearInterval(timerInterval);
      }
    };
  }, [timerInterval]);
  
  // Format recording duration as MM:SS
  const formatDuration = (ms) => {
    const totalSeconds = Math.floor(ms / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };
  
  // Calculate remaining time
  const remainingTime = MAX_DURATION_MS - recordingDuration;
  const remainingPercent = (remainingTime / MAX_DURATION_MS) * 100;
  const isTimeWarning = remainingTime < 30000; // Less than 30 seconds remaining
  
  return (
    <div className="relative">
      <Button
        variant={(isHolding || isRecording) ? "secondary" : "primary"}
        size="lg"
        fullWidth
        className={`
          relative transition-all duration-200 flex items-center justify-center
          ${(isHolding || isRecording) ? 'bg-yt-darker shadow-inner' : 'shadow-lg'}
          ${disabled ? 'opacity-50 cursor-not-allowed' : ''}
        `}
        icon={<FiMic size={20} className={(isHolding || isRecording) ? 'animate-pulse' : ''} />}
        onMouseDown={handleHoldStart}
        onTouchStart={handleHoldStart}
        disabled={disabled}
      >
        {isHolding || isRecording ? 'Listening...' : 'Talk'}
        
        {/* Show recording duration if active */}
        {(isHolding || isRecording) && !compact && (
          <span className="ml-2 text-xs">
            {formatDuration(recordingDuration)}
          </span>
        )}
        
        {/* For compact view, show shorter duration */}
        {(isHolding || isRecording) && compact && (
          <span className="ml-1 text-xs">
            {formatDuration(recordingDuration).substring(3)} {/* Only show seconds */}
          </span>
        )}
        
        {/* Ripple effect when holding */}
        {(isHolding || isRecording) && (
          <span className="absolute w-full h-full">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-lg bg-yt-red opacity-10"></span>
          </span>
        )}
      </Button>
      
      {/* Remaining time progress bar */}
      {(isHolding || isRecording) && (
        <div className="w-full h-1 bg-yt-darker mt-1 rounded-full overflow-hidden">
          <div 
            className={`h-full transition-all ${isTimeWarning ? 'bg-yellow-500' : 'bg-yt-red'}`} 
            style={{ width: `${remainingPercent}%` }}
          ></div>
        </div>
      )}
      
      {/* Time limit warning - only show in non-compact mode */}
      {(isHolding || isRecording) && isTimeWarning && !compact && (
        <div className="text-xs text-center mt-1 text-yellow-400">
          {remainingTime <= 0 
            ? 'Time limit reached!' 
            : `${Math.ceil(remainingTime / 1000)}s remaining`}
        </div>
      )}
    </div>
  );
};

export default TalkButton;