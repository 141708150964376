// src/hooks/useAudioRecorder.js
import { useState, useRef, useCallback, useEffect } from 'react';

/**
 * Custom hook for recording audio with time limit and permission handling
 * Enhanced with complete media release functionality
 * @param {Object} options - Configuration options
 * @param {number} options.maxDuration - Maximum recording duration in milliseconds (default: 2 minutes)
 * @param {number} options.sampleRate - Sample rate in Hz (default: 44100)
 * @param {string} options.mimeType - MIME type for recording (default: 'audio/mp3')
 * @returns {Object} Audio recorder state and controls
 */
const useAudioRecorder = ({
  maxDuration = 120000, // 2 minutes in milliseconds
  sampleRate = 44100,
  mimeType = 'audio/mp3',
} = {}) => {
  // Recording state
  const [isRecording, setIsRecording] = useState(false);
  const [hasPermission, setHasPermission] = useState(null); // null = unknown, true/false = granted/denied
  const [recordedBlob, setRecordedBlob] = useState(null);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const [error, setError] = useState(null);
  const [isLimitReached, setIsLimitReached] = useState(false);
  
  // Refs for capturing objects that need to persist between renders
  const mediaRecorderRef = useRef(null);
  const streamRef = useRef(null);
  const chunksRef = useRef([]);
  const startTimeRef = useRef(null);
  const timerRef = useRef(null);
  const durationTimerRef = useRef(null);
  
  /**
   * Completely release the media stream
   * This is crucial to ensure the microphone is fully released after use
   */
  const releaseMediaStream = useCallback(() => {
    console.log('[AudioRecorder] Completely releasing media stream');
    
    if (streamRef.current) {
      // Stop all tracks in the stream to fully release the microphone
      streamRef.current.getTracks().forEach(track => {
        track.stop();
        console.log(`[AudioRecorder] Stopped track: ${track.kind}, readyState: ${track.readyState}`);
      });
      
      // Clear the stream reference
      streamRef.current = null;
    }
  }, []);
  
  // Cleanup resources
  const cleanup = useCallback(() => {
    // Clear timers
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    
    if (durationTimerRef.current) {
      clearInterval(durationTimerRef.current);
      durationTimerRef.current = null;
    }
    
    // Stop media recorder if active
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      try {
        mediaRecorderRef.current.stop();
      } catch (err) {
        console.error('[AudioRecorder] Error stopping recorder:', err);
      }
    }
    
    // Reset recording state
    setIsRecording(false);
    startTimeRef.current = null;
  }, []);
  
  // Effect to clean up on unmount
  useEffect(() => {
    return () => {
      cleanup();
      // Also ensure the media stream is fully released on unmount
      releaseMediaStream();
    };
  }, [cleanup, releaseMediaStream]);
  
  /**
   * Check microphone permission WITHOUT activating the microphone
   * This only checks permission status without requesting the stream
   * @returns {Promise<string>} Permission status: 'granted', 'denied', or 'prompt'
   */
  const checkPermissionStatus = useCallback(async () => {
    try {
      // Only use the Permissions API, which doesn't activate the microphone
      if (navigator.permissions && navigator.permissions.query) {
        console.log('[AudioRecorder] Checking microphone permission status');
        const permissionStatus = await navigator.permissions.query({ name: 'microphone' });
        
        console.log(`[AudioRecorder] Permission status: ${permissionStatus.state}`);
        
        // Update our internal state
        if (permissionStatus.state === 'granted') {
          setHasPermission(true);
        } else if (permissionStatus.state === 'denied') {
          setHasPermission(false);
        } else {
          // 'prompt' state - we don't know yet
          setHasPermission(null);
        }
        
        return permissionStatus.state;
      } else {
        // Browser doesn't support the Permissions API
        console.log('[AudioRecorder] Permissions API not supported, will prompt when needed');
        return 'prompt';
      }
    } catch (err) {
      console.error('[AudioRecorder] Error checking permission status:', err);
      return 'prompt'; // Default to prompt if we can't check
    }
  }, []);
  
  /**
   * Request microphone permission and activate the stream
   * This actually turns on the microphone
   * @returns {Promise<boolean>} Whether permission was granted and stream created
   */
  const requestMicrophoneAccess = useCallback(async () => {
    setError(null);
    
    try {
      console.log('[AudioRecorder] Requesting microphone access (will activate mic)');
      
      // First, release any existing stream to avoid multiple active streams
      releaseMediaStream();
      
      // This will both prompt for permission if needed AND activate the microphone
      const stream = await navigator.mediaDevices.getUserMedia({ audio: {
        sampleRate,
        channelCount: 1,
        echoCancellation: true,
        noiseSuppression: true,
      }});
      
      // If we get here, permission was granted and mic is active
      console.log('[AudioRecorder] Microphone access granted, stream created');
      setHasPermission(true);
      
      // Store the stream for later use
      streamRef.current = stream;
      
      return true;
    } catch (err) {
      console.error('[AudioRecorder] Microphone access error:', err);
      
      if (err.name === 'NotAllowedError' || err.name === 'PermissionDeniedError') {
        setHasPermission(false);
        setError('Microphone permission denied. Please allow microphone access.');
      } else {
        setError(`Microphone error: ${err.message}`);
      }
      
      return false;
    }
  }, [sampleRate, releaseMediaStream]);
  
  /**
   * Stop recording and process the audio
   * @returns {Promise<Blob|null>} Recorded audio blob
   */
  const stopRecording = useCallback(async () => {
    if (!isRecording || !mediaRecorderRef.current) {
      console.log('[AudioRecorder] Stop called but not recording');
      return null;
    }
    
    console.log('[AudioRecorder] Stopping recording');
    
    return new Promise((resolve) => {
      // Create one-time event handler to resolve after processing
      const onStopComplete = () => {
        mediaRecorderRef.current.removeEventListener('stop', onStopComplete);
        console.log('[AudioRecorder] Recording stopped and processed');
        resolve(recordedBlob);
      };
      
      // Add the event listener
      mediaRecorderRef.current.addEventListener('stop', onStopComplete);
      
      // Stop the recording
      try {
        mediaRecorderRef.current.stop();
        
        // Clear the timeout timer
        if (timerRef.current) {
          clearTimeout(timerRef.current);
          timerRef.current = null;
        }
        
        // Don't call cleanup() here as we need to wait for the onstop event
      } catch (err) {
        console.error('[AudioRecorder] Error stopping recording:', err);
        cleanup();
        resolve(null);
      }
    });
  }, [isRecording, recordedBlob, cleanup]);
  
  /**
   * Start recording audio - this will activate the microphone
   * @returns {Promise<boolean>} Whether recording started successfully
   */
  const startRecording = useCallback(async () => {
    // Reset state
    setError(null);
    setIsLimitReached(false);
    setRecordedBlob(null);
    chunksRef.current = [];
    
    try {
      // We need to get the stream (activate microphone) if we don't have it already
      if (!streamRef.current) {
        const accessGranted = await requestMicrophoneAccess();
        if (!accessGranted) {
          return false;
        }
      }
      
      // Determine available mime types
      const mimeTypes = [
        'audio/mp3',
        'audio/mpeg',
        'audio/webm',
        'audio/webm;codecs=opus',
        'audio/ogg;codecs=opus'
      ];
      
      // Find a supported mime type
      let recordingMimeType = null;
      for (const type of mimeTypes) {
        if (MediaRecorder.isTypeSupported(type)) {
          recordingMimeType = type;
          break;
        }
      }
      
      if (!recordingMimeType) {
        throw new Error('No supported audio MIME types found');
      }
      
      console.log(`[AudioRecorder] Using MIME type: ${recordingMimeType}`);
      
      // Create media recorder
      const mediaRecorder = new MediaRecorder(streamRef.current, {
        mimeType: recordingMimeType,
        audioBitsPerSecond: 128000, // 128 kbps
      });
      
      // Set up event handlers
      mediaRecorder.ondataavailable = (event) => {
        if (event.data && event.data.size > 0) {
          chunksRef.current.push(event.data);
        }
      };
      
      mediaRecorder.onstop = () => {
        // Create a blob from all chunks
        if (chunksRef.current.length > 0) {
          console.log(`[AudioRecorder] Recording stopped, processing ${chunksRef.current.length} chunks`);
          const blob = new Blob(chunksRef.current, { type: recordingMimeType });
          console.log(`[AudioRecorder] Created audio blob: size=${blob.size} bytes, type=${blob.type}`);
          setRecordedBlob(blob);
        } else {
          console.warn('[AudioRecorder] No audio data recorded (empty chunks)');
        }
        
        // Cleanup
        if (durationTimerRef.current) {
          clearInterval(durationTimerRef.current);
          durationTimerRef.current = null;
        }
        
        setIsRecording(false);
      };
      
      // Start recording
      mediaRecorder.start(1000); // Request data every second
      mediaRecorderRef.current = mediaRecorder;
      
      // Set recording state
      setIsRecording(true);
      startTimeRef.current = Date.now();
      
      // Create duration timer to update UI
      durationTimerRef.current = setInterval(() => {
        if (startTimeRef.current) {
          const elapsed = Date.now() - startTimeRef.current;
          setRecordingDuration(elapsed);
        }
      }, 100);
      
      // Set timeout for max duration
      timerRef.current = setTimeout(() => {
        if (isRecording) {
          console.log(`[AudioRecorder] Recording limit reached (${maxDuration}ms)`);
          setIsLimitReached(true);
          stopRecording();
        }
      }, maxDuration);
      
      return true;
    } catch (err) {
      console.error('[AudioRecorder] Error starting recording:', err);
      setError(`Failed to start recording: ${err.message}`);
      cleanup();
      
      // Make sure to release the media stream on error
      releaseMediaStream();
      
      return false;
    }
  }, [cleanup, isRecording, maxDuration, requestMicrophoneAccess, stopRecording, releaseMediaStream]);
  
  /**
   * Reset the recorder state
   */
  const resetRecorder = useCallback(() => {
    setRecordedBlob(null);
    setRecordingDuration(0);
    setError(null);
    setIsLimitReached(false);
    chunksRef.current = [];
  }, []);
  
  return {
    // State
    isRecording,
    recordedBlob,
    recordingDuration,
    hasPermission,
    error,
    isLimitReached,
    
    // Methods
    startRecording,
    stopRecording,
    checkPermissionStatus,  // Only checks permission status
    requestMicrophoneAccess, // Requests permission AND activates mic
    resetRecorder,
    releaseMediaStream, // New method to completely release media stream
  };
};

export default useAudioRecorder;