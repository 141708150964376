// src/components/creator/ChannelCard.jsx
import React from "react";
import { FiVideo } from "react-icons/fi";
import { Link } from "react-router-dom"; // Import Link
import Button from "../common/Button";

const ChannelCard = ({ channel, onCreateContent }) => {
  const { channel_id, channel_title, channel_description } = channel;

  // Generate a color based on the channel_id (just for visual variety)
  const generateColor = (id) => {
    // ... (keep existing generateColor function)
    const colors = ["#e74c3c", "#2ecc71", "#3498db", "#9b59b6", "#f39c12"];
    const hash = id.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return colors[hash % colors.length];
  };

  const bgColor = generateColor(channel_id);

  // Truncate description to first 200 characters
  const truncateDescription = (text) => {
    // ... (keep existing truncateDescription function)
     if (!text) return "No description";
    return text.length > 200 ? `${text.substring(0, 200)}...` : text;
  };

  // Handle button click - stop propagation
  const handleCreateContentClick = (e) => {
    e.stopPropagation(); // Prevent the Link's navigation
    onCreateContent(channel);
  };

  return (
    // Wrap the outer div, but the button will be outside the main clickable area logic conceptually
    <div className="bg-yt-darker rounded-lg overflow-hidden hover:shadow-lg transition-shadow h-full flex flex-col">
      {/* Make the top part (banner and info) clickable */}
      <Link to={`/channels/${channel_id}`} className="flex-grow flex flex-col cursor-pointer">
        {/* Channel Banner */}
        <div
          className="h-32 w-full"
          style={{ backgroundColor: bgColor }}
        ></div>

        {/* Channel Info */}
        <div className="p-6 flex-grow flex flex-col">
          <h3 className="text-xl font-medium text-yt-text truncate mb-3">
            {channel_title}
          </h3>

          <p className="text-yt-text-secondary text-sm mb-5 flex-grow">
            {truncateDescription(channel_description)}
          </p>
        </div>
      </Link>

      {/* Button Section - outside the main Link's clickable area for content, but physically inside the card */}
      <div className="p-6 pt-0 flex justify-end"> {/* Adjusted padding */}
        <Button
          variant="primary"
          size="md"
          icon={<FiVideo size={16} />}
          onClick={handleCreateContentClick} // Use the handler that stops propagation
        >
          Create Content
        </Button>
      </div>
    </div>
  );
};

export default ChannelCard;