// src/services/authService.js
import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  onAuthStateChanged,
  signOut as firebaseSignOut
} from 'firebase/auth';
import { auth } from './firebaseConfig';
import useAuthStore from '../store/authStore';

/**
 * Sign up with email and password
 * @param {string} email User's email
 * @param {string} password User's password
 * @returns {Promise<Object>} User credential
 */
export const signUpWithEmail = async (email, password) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    // Send verification email with custom URL
    await sendEmailVerification(userCredential.user, {
      url: window.location.origin + '/signin', // Redirect to sign in page after verification
    });
    
    console.log('User created successfully:', userCredential.user.email);
    
    // Sign out the user immediately after signup and verification email is sent
    // This prevents auto-access to protected routes before verification
    await firebaseSignOut(auth);
    useAuthStore.getState().signOut(); // Update Zustand store
    
    return userCredential.user;
  } catch (error) {
    console.error('Error signing up with email:', error);
    throw error;
  }
};

/**
 * Sign in with email and password
 * @param {string} email User's email
 * @param {string} password User's password
 * @returns {Promise<Object>} User credential
 */
export const signInWithEmail = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    
    if (!userCredential.user.emailVerified) {
      // If email is not verified, sign out and throw an error
      await firebaseSignOut(auth);
      useAuthStore.getState().signOut();
      throw new Error('Email not verified. Please check your inbox for the verification link.');
    }
    
    console.log('Email sign-in successful:', userCredential.user.email);
    return userCredential.user;
  } catch (error) {
    console.error('Error signing in with email:', error);
    throw error;
  }
};

/**
 * Send password reset email
 * @param {string} email User's email
 * @returns {Promise<void>}
 */
export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email, {
      url: window.location.origin + '/signin', // Redirect to sign in page after reset
    });
    console.log('Password reset email sent to:', email);
  } catch (error) {
    console.error('Error sending password reset email:', error);
    throw error;
  }
};

/**
 * Sign out the current user
 * @returns {Promise<void>}
 */
export const signOut = async () => {
  try {
    await firebaseSignOut(auth);
    useAuthStore.getState().signOut(); // Update Zustand store
  } catch (error) {
    console.error('Error signing out:', error);
    throw error;
  }
};

/**
 * Initialize auth state listener
 * Call this once when your app initializes
 */
export const initAuthListener = () => {
  const { setUser, setLoading } = useAuthStore.getState();
  
  setLoading(true);
  
  // This sets up a persistent listener
  return onAuthStateChanged(auth, (user) => {
    if (user) {
      setUser(user);
    } else {
      setUser(null);
    }
    setLoading(false);
  });
};