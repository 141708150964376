import React from "react";
import SessionCard from "./SessionCard";
import SkeletonLoader from "../common/SkeletonLoader";

const SessionGrid = ({ content, isLoading, error }) => {
  if (isLoading) {
    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {Array(8).fill(0).map((_, index) => (
          <SkeletonLoader key={index} type="card" />
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-64 text-red-500">
        Error loading content: {error.message || "Unknown error"}
      </div>
    );
  }

  if (!content || content.length === 0) {
    return (
      <div className="flex justify-center items-center h-64 text-yt-text-secondary">
        No content found
      </div>
    );
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
      {content.map((item) => (
        <SessionCard key={item.content_id} content={item} />
      ))}
    </div>
  );
};

export default SessionGrid;