// src/components/auth/EmailVerificationHandler.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { applyActionCode, signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../services/firebaseConfig';
import LoadingScreen from '../common/LoadingScreen';
import Button from '../common/Button';

const EmailVerificationHandler = () => {
  const [verificationState, setVerificationState] = useState('loading');
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signingIn, setSigningIn] = useState(false);
  const [signInError, setSignInError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        // Get the action code from the URL query parameters
        const queryParams = new URLSearchParams(location.search);
        const actionCode = queryParams.get('oobCode');
        // Try to get email from the URL if available
        const emailParam = queryParams.get('email');
        
        if (!actionCode) {
          setVerificationState('error');
          setError('Invalid verification link');
          return;
        }

        // Apply the verification code
        await applyActionCode(auth, actionCode);
        setVerificationState('success');
        
        // If we have an email, pre-fill it for sign-in
        if (emailParam) {
          setEmail(emailParam);
        }
      } catch (error) {
        console.error('Error verifying email:', error);
        setVerificationState('error');
        setError(error.message || 'Failed to verify email');
      }
    };

    verifyEmail();
  }, [location]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    
    if (!email || !password) {
      setSignInError('Please enter both email and password');
      return;
    }
    
    setSigningIn(true);
    setSignInError(null);
    
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/feed');
    } catch (error) {
      console.error('Error signing in after verification:', error);
      
      let errorMessage = 'Failed to sign in. Please check your credentials.';
      if (error.code === 'auth/wrong-password') {
        errorMessage = 'Incorrect password. Please try again.';
      } else if (error.code === 'auth/user-not-found') {
        errorMessage = 'No account found with this email address.';
      }
      
      setSignInError(errorMessage);
    } finally {
      setSigningIn(false);
    }
  };

  if (verificationState === 'loading') {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen bg-yt-black flex items-center justify-center p-4">
      <div className="bg-yt-darker rounded-lg shadow-xl max-w-md w-full p-8 animate-fade-in text-center">
        <h1 className="text-2xl font-bold mb-6">
          <span className="text-yt-red">Meta</span>
          <span className="text-white">Blogger</span>
        </h1>
        
        {verificationState === 'success' ? (
          <>
            <div className="bg-green-900 bg-opacity-30 border border-green-800 text-white p-4 rounded-md mb-6">
              <p className="text-xl font-semibold mb-2">✓ Email Verified!</p>
              <p>Your email has been successfully verified.</p>
            </div>
            
            <div className="space-y-4">
              <p className="text-white">Sign in to continue to MetaBlogger</p>
              
              {signInError && (
                <div className="bg-red-900 bg-opacity-30 border border-red-800 text-white p-3 rounded-md">
                  {signInError}
                </div>
              )}
              
              <form onSubmit={handleSignIn} className="space-y-4">
                <div>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email address"
                    className="w-full bg-yt-light text-white px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-yt-red"
                    required
                  />
                </div>
                
                <div>
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                    className="w-full bg-yt-light text-white px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-yt-red"
                    required
                  />
                </div>
                
                <Button 
                  type="submit"
                  variant="primary"
                  fullWidth
                  disabled={signingIn}
                >
                  {signingIn ? 'Signing In...' : 'Sign In and Continue to App'}
                </Button>
              </form>
              
              <div className="pt-4 border-t border-yt-light">
                <Button 
                  onClick={() => navigate('/signin')}
                  variant="outline"
                  fullWidth
                >
                  Return to Sign In Page
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="bg-red-900 bg-opacity-30 border border-red-800 text-white p-4 rounded-md mb-6">
              <p className="text-xl font-semibold mb-2">Verification Failed</p>
              <p>{error || 'Unable to verify your email. The link may have expired.'}</p>
            </div>
            <div className="space-y-4">
              <Button 
                onClick={() => navigate('/signin')}
                variant="primary"
                fullWidth
              >
                Go to Sign In
              </Button>
              <Button 
                onClick={() => navigate('/')}
                variant="outline"
                fullWidth
              >
                Return to Home
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmailVerificationHandler;