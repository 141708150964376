import React from 'react';
import { Link } from 'react-router-dom';

const Logo = ({ collapsed = false }) => {
  return (
    <Link to="/" className="flex items-center no-underline">
      <div className="text-yt-red font-bold">
        {collapsed ? (
          <div className="text-xl">MB</div>
        ) : (
          <div className="flex items-center">
            <span className="text-xl">Meta</span>
            <span className="text-xl text-white">Blogger</span>
          </div>
        )}
      </div>
    </Link>
  );
};

export default Logo;