// src/store/authStore.js
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const useAuthStore = create(
  persist(
    (set) => ({
      user: null,
      loading: true,
      error: null,
      setUser: (user) => set({ user, loading: false }),
      setLoading: (loading) => set({ loading }),
      setError: (error) => set({ error, loading: false }),
      signOut: () => set({ user: null, error: null }),
      clearError: () => set({ error: null }),
    }),
    {
      name: 'auth-storage', // name for the storage key
    }
  )
);

export default useAuthStore;