// src/pages/SessionPage.jsx
import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { FiThumbsUp, FiEye, FiClock } from "react-icons/fi";
import { useQuery } from "@tanstack/react-query";
import Button from "../components/common/Button";
import ErrorMessage from "../components/common/ErrorMessage";
import LoadingScreen from "../components/common/LoadingScreen";
import AudioPlayer from "../components/audio/AudioPlayer";
import { fetchContentDetails } from "../services/contentService";
import { getThumbnailUrl } from "../services/storageService";

// Helper function to format numbers with K/M suffix (copied from SessionCard)
const formatNumber = (num) => {
  if (num === undefined || num === null) return "0";
  if (num >= 1000000) return (num / 1000000).toFixed(1) + "M";
  if (num >= 1000) return (num / 1000).toFixed(1) + "K";
  return num.toString();
};

const SessionPage = () => {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [thumbnailUrl, setThumbnailUrl] = useState(null);
  
  // Fetch session details
  const {
    data: sessionDetails,
    isLoading: isLoadingSession,
    error: sessionError
  } = useQuery(
    ["content-details", id], 
    () => fetchContentDetails(id),
    {
      enabled: !!id,
      staleTime: 300000, // 5 minutes
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(`[SessionPage] Error loading session ${id}:`, err);
        setError(`Error loading session: ${err.message || "Unknown error"}`);
      }
    }
  );

  // Load thumbnail when session details are available
  useEffect(() => {
    const loadThumbnail = async () => {
      if (sessionDetails?.thumbnail_path) {
        try {
          const url = await getThumbnailUrl(sessionDetails.thumbnail_path);
          setThumbnailUrl(url);
        } catch (err) {
          console.error("Error loading thumbnail:", err);
          // Don't set error state here to avoid blocking the whole page
        }
      }
    };

    loadThumbnail();
  }, [sessionDetails]);

  // Create data URI for fallback image (same as in current placeholder)
  const placeholderImage =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 180' width='320' height='180'%3E%3Crect width='320' height='180' fill='%23212121'/%3E%3Ctext x='50%25' y='50%25' dominant-baseline='middle' text-anchor='middle' font-family='sans-serif' font-size='18' fill='%23aaaaaa'%3ENo Thumbnail%3C/text%3E%3C/svg%3E";

  // Clear errors
  const clearError = () => setError(null);

  // Handle full page loading state
  if (isLoadingSession && !sessionDetails) {
    return <LoadingScreen />;
  }

  // Handle errors
  if (sessionError || error) {
    return <ErrorMessage message={error || sessionError.message} onClose={clearError} />;
  }

  return (
    <div className="max-w-4xl mx-auto py-4">
      <div className="bg-yt-darker rounded-lg overflow-hidden">
        {/* Thumbnail Section */}
        <div className="w-full aspect-video bg-yt-black relative">
          <img
            src={thumbnailUrl || placeholderImage}
            alt={sessionDetails?.title || `Session ${id}`}
            className="w-full h-full object-cover"
            onError={(e) => {
              if (e.target.src !== placeholderImage) {
                console.error(`[DEBUG] Thumbnail load error for session ${id}`);
                e.target.src = placeholderImage;
              }
            }}
          />
          
          {/* Duration indicator (if available) */}
          {sessionDetails?.duration && (
            <div className="absolute bottom-2 right-2 bg-black bg-opacity-80 px-1 py-0.5 text-xs text-white rounded flex items-center">
              <FiClock className="mr-1" size={12} />
              {sessionDetails.duration}
            </div>
          )}
        </div>

        {/* Session Info */}
        <div className="p-4">
          <h1 className="text-xl font-bold text-yt-text mb-2">
            {sessionDetails?.title || sessionDetails?.script_title || `Session ${id}`}
          </h1>
          
          {/* Stats row */}
          <div className="flex flex-wrap items-center justify-between mb-4">
            <div className="flex items-center">
              {/* Channel title - clickable */}
              {sessionDetails?.channel_id && (
                <Link 
                  to={`/channels/${sessionDetails.channel_id}`} 
                  className="text-sm text-yt-text-secondary hover:text-yt-red mr-4"
                >
                  {sessionDetails.channel_title || "Channel"}
                </Link>
              )}
              
              {/* View count */}
              <div className="flex items-center text-sm text-yt-text-secondary mr-3">
                <FiEye size={16} className="mr-1" />
                <span>{formatNumber(sessionDetails?.view_count || 0)} views</span>
              </div>
              
              {/* Like count */}
              <div className="flex items-center text-sm text-yt-text-secondary">
                <FiThumbsUp size={16} className="mr-1" />
                <span>{formatNumber(sessionDetails?.like_count || 0)}</span>
              </div>
            </div>
            
 
          </div>
          
          {/* Description */}
          {sessionDetails?.content_description && (
            <div className="border-t border-yt-light pt-4 mb-6">
              <div className="text-yt-text-secondary whitespace-pre-line">
                {sessionDetails.content_description}
              </div>
            </div>
          )}
          
          {/* Audio Player */}
          {sessionDetails && (
            <AudioPlayer 
              sessionId={id} 
              sessionDetails={sessionDetails} 
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SessionPage;