// src/services/channelService.js
import axios from 'axios';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from './firebaseConfig';

const API_BASE_URL = 'https://api.alindor.tech'; // Your API base URL

// Create axios instance with default config
const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

/**
 * Create a new channel
 * @param {Object} channelData - Channel data including title, voice_id, description, knowledge bank, and user_id
 * @returns {Promise<Object>} Result of channel creation
 */
export const createChannel = async (channelData) => {
  try {
    const response = await api.post('/api/channels/', channelData);
    
    // Return standardized response
    if (response.data.success) {
      return {
        success: true,
        message: response.data.message,
        data: response.data.data,
      };
    } else {
      return {
        success: false,
        message: response.data.message || 'Failed to create channel',
        data: null,
      };
    }
  } catch (error) {
    console.error('Error creating channel:', error);
    
    // Handle error response from API
    if (error.response?.data?.message) {
      return {
        success: false,
        message: error.response.data.message,
        data: null,
      };
    }
    
    // Handle network or other errors
    return {
      success: false,
      message: error.message || 'An error occurred while creating the channel',
      data: null,
    };
  }
};

/**
 * Fetch all channels for a specific user
 * @param {string} userId - User ID
 * @returns {Promise<Array>} List of user's channels
 */
export const fetchUserChannels = async (userId) => {
  try {
    // Create a query against the channels collection
    const channelsQuery = query(
      collection(db, 'channels'),
      where('channel_creator_user_id', '==', userId)
    );
    
    // Execute the query
    const querySnapshot = await getDocs(channelsQuery);
    
    // Map the results to the expected format
    const channels = querySnapshot.docs.map(doc => ({
      channel_id: doc.id,
      channel_title: doc.data().channel_title,
      channel_description: doc.data().channel_description,
      voice_id: doc.data().voice_id,
      channel_knowledge_bank: doc.data().channel_knowledge_bank,
      user_id: doc.data().channel_creator_user_id
    }));
    
    console.log(`Found ${channels.length} channels for user ${userId}`);
    return channels;
  } catch (error) {
    console.error('Error fetching user channels:', error);
    throw new Error(`Failed to fetch channels: ${error.message}`);
  }
};

/**
 * Fetch a specific channel by ID
 * @param {string} channelId - Channel ID
 * @returns {Promise<Object>} Channel details
 */
export const fetchChannel = async (channelId) => {
  try {
    const response = await api.get(`/api/channels/${channelId}`);
    
    if (response.data.success) {
      return response.data.data;
    } else {
      throw new Error(response.data.message || 'Failed to fetch channel');
    }
  } catch (error) {
    console.error(`Error fetching channel ${channelId}:`, error);
    throw error;
  }
};