import React from 'react';
import { NavLink } from 'react-router-dom';
import { RiSettings4Line } from 'react-icons/ri';
import { MdOutlinePlaylistPlay } from 'react-icons/md';
import { FiEdit } from 'react-icons/fi';
import Logo from '../common/Logo';
import useUiStore from '../../store/uiStore';

const Sidebar = () => {
  const { sidebarOpen, setActivePage } = useUiStore();

  const navItems = [
    { 
      icon: <MdOutlinePlaylistPlay size={24} />, 
      text: 'Feed', 
      path: '/feed' 
    },
    { icon: <FiEdit size={24} />, text: 'Creator', path: '/creator' },
    { icon: <RiSettings4Line size={24} />, text: 'Settings', path: '/settings' },
  ];

  return (
    <div 
      className={`
        fixed top-0 left-0 h-full bg-yt-black transition-all duration-300 ease-in-out z-10
        ${sidebarOpen ? 'w-60' : 'w-20'}
        flex flex-col border-r border-yt-darker
      `}
    >
      <div className="py-4 px-4 flex justify-center items-center h-14">
        <Logo collapsed={!sidebarOpen} />
      </div>
      
      <nav className="mt-6 flex-1 overflow-y-auto no-scrollbar">
        <ul className="px-2 space-y-1">
          {navItems.map((item) => (
            <li key={item.path}>
              <NavLink 
                to={item.path}
                onClick={() => setActivePage(item.text.toLowerCase())}
                className={({ isActive }) => `
                  flex items-center px-4 py-3 rounded-md transition-colors
                  ${isActive ? 'bg-yt-light text-white' : 'text-yt-text-secondary hover:bg-yt-darker'}
                `}
              >
                <span className="flex-shrink-0">{item.icon}</span>
                {sidebarOpen && (
                  <span className="ml-4 text-sm font-medium">{item.text}</span>
                )}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      
      <div className="mt-auto p-4 border-t border-yt-darker">
        <div className={`text-yt-text-secondary text-xs ${!sidebarOpen && 'text-center'}`}>
          {sidebarOpen ? '© 2025 MetaBlogger' : '© 2025'}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;