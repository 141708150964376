// src/hooks/useContent.js
import { useState, useEffect, useRef } from 'react';
import { useQuery } from '@tanstack/react-query';
import { fetchCompletedContent, fetchChannelContent } from '../services/firestoreService';
import { getThumbnailUrl } from '../services/storageService';

// Batch size for processing thumbnails
const BATCH_SIZE = 5;

/**
 * Process thumbnails in batches to improve loading performance
 * @param {Array} items - Content items to process
 * @param {Function} updateCallback - Callback to update processed items
 */
const processThumbnailsInBatches = async (items, updateCallback) => {
  // First return all items without thumbnails to display content quickly
  const initialItems = items.map(item => ({
    ...item,
    thumbnailUrl: null, // Start with null thumbnails
    title: item.script_title || item.title,
    duration: item.duration || null,
    view_count: item.view_count || 0,
    like_count: item.like_count || 0,
  }));
  
  // Update state with initial items (no thumbnails yet)
  updateCallback(initialItems);
  
  // Process thumbnails in batches
  const batches = Math.ceil(items.length / BATCH_SIZE);
  const processedItems = [...initialItems]; // Clone to modify
  
  for (let batchIndex = 0; batchIndex < batches; batchIndex++) {
    const batchStart = batchIndex * BATCH_SIZE;
    const batchEnd = Math.min(batchStart + BATCH_SIZE, items.length);
    const currentBatch = items.slice(batchStart, batchEnd);
    
    // Process this batch
    const batchPromises = currentBatch.map(async (item, index) => {
      try {
        // Only fetch thumbnail if we have a path
        if (item.thumbnail_path) {
          const thumbnailUrl = await getThumbnailUrl(item.thumbnail_path);
          
          // Update the corresponding item in our processed array
          const itemIndex = batchStart + index;
          
          if (thumbnailUrl) {
            processedItems[itemIndex] = {
              ...processedItems[itemIndex],
              thumbnailUrl,
            };
          } else {
            // Set an error state for the thumbnail
            processedItems[itemIndex] = {
              ...processedItems[itemIndex],
              thumbnailUrl: null,
              thumbnailError: true,
            };
          }
        }
      } catch (error) {
        console.warn(`[useContent] Error processing thumbnail for item ${item.content_id}:`, error);
        
        // Mark this item with an error
        const itemIndex = batchStart + index;
        processedItems[itemIndex] = {
          ...processedItems[itemIndex],
          thumbnailUrl: null,
          thumbnailError: true,
        };
      }
    });
    
    // Wait for all thumbnails in this batch to process
    await Promise.all(batchPromises);
    
    // Update state after each batch completes
    updateCallback([...processedItems]);
  }
};

/**
 * Custom hook for fetching and processing content
 * @param {Object} options - Options for content fetching
 * @param {string} options.channelId - Optional channel ID to filter content
 * @param {string} options.channelTitle - Channel title for context
 * @returns {Object} Processed content with loading/error states
 */
const useContent = ({ channelId, channelTitle } = {}) => {
  const [processedContent, setProcessedContent] = useState([]);
  const [error, setError] = useState(null);
  const processingRef = useRef(false);
  
  // Creating a unique query key based on what we're fetching
  const queryKey = channelId ? ['channel-content', channelId] : ['content-completed'];
  
  // Clear error function
  const clearError = () => setError(null);
  
  // Determine which fetch function to use based on channelId
  const fetchFunction = channelId ? 
    () => fetchChannelContent(channelId) : 
    () => fetchCompletedContent();
  
  // Fetch content using react-query with longer cache time
  const {
    data: rawContent,
    isLoading,
    error: queryError,
    refetch,
  } = useQuery(
    queryKey,
    fetchFunction,
    {
      staleTime: 300000, // 5 minutes
      cacheTime: 3600000, // 1 hour
      refetchOnWindowFocus: false,
      onError: (err) => {
        setError(`Failed to load content: ${err.message || "Unknown error"}`);
      },
    }
  );
  
  // Process content to add thumbnails and consistent formatting
  useEffect(() => {
    // Skip if already processing to avoid race conditions
    if (processingRef.current) {
      return;
    }
    
    if (!rawContent || !Array.isArray(rawContent)) {
      return;
    }
    
    processingRef.current = true;
    
    // Prepare the content with channel information
    const contentItems = rawContent.map(item => ({
      ...item,
      channel_title: item.channel_title || channelTitle || item.channel_id,
    }));
    
    // Process thumbnails in batches and update state incrementally
    processThumbnailsInBatches(contentItems, (updatedItems) => {
      setProcessedContent(updatedItems);
    }).catch(err => {
      console.error(`[useContent] Error processing content:`, err);
      setError(`Error processing content: ${err.message || "Unknown error"}`);
    }).finally(() => {
      processingRef.current = false;
    });
    
  }, [rawContent, channelId, channelTitle]);
  
  return {
    content: processedContent,
    isLoading,
    error: error || queryError,
    clearError,
    refetch,
  };
};

export default useContent;