// src/pages/FeedPage.jsx
import React from "react";
import useContent from "../hooks/useContent";
import ContentDisplay from "../components/content/ContentDisplay";

const FeedPage = () => {
  // Use the custom hook for content fetching
  const { content, isLoading, error, clearError, refetch } = useContent();

  return (
    <ContentDisplay
      title="Feed"
      content={content}
      isLoading={isLoading}
      error={error}
      clearError={clearError}
      refetch={refetch}
    />
  );
};

export default FeedPage;