import React from 'react';

const SkeletonLoader = ({ type = 'card', count = 1 }) => {
  const renderSkeleton = () => {
    switch (type) {
      case 'card':
        return (
          <div className="bg-yt-darker rounded-lg overflow-hidden animate-pulse">
            {/* Thumbnail skeleton */}
            <div className="w-full aspect-video bg-yt-light"></div>
            
            {/* Content skeleton */}
            <div className="p-3">
              {/* Title skeleton */}
              <div className="h-4 bg-yt-light rounded w-full mb-2"></div>
              <div className="h-4 bg-yt-light rounded w-2/3 mb-2"></div>
              
              {/* Channel name skeleton */}
              <div className="h-3 bg-yt-light rounded w-1/2 mb-3"></div>
              
              {/* Stats skeleton */}
              <div className="flex items-center">
                <div className="h-3 bg-yt-light rounded w-16 mr-3"></div>
                <div className="h-3 bg-yt-light rounded w-16 mr-3"></div>
                <div className="h-3 bg-yt-light rounded w-16"></div>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };
  
  if (count === 1) {
    return renderSkeleton();
  }
  
  return (
    <>
      {Array(count).fill(0).map((_, index) => (
        <div key={index}>{renderSkeleton()}</div>
      ))}
    </>
  );
};

export default SkeletonLoader;