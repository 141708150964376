// src/pages/SignUpPage.jsx
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../components/common/Button';
import useAuthStore from '../store/authStore';
import { signUpWithEmail } from '../services/authService';
import LoadingScreen from '../components/common/LoadingScreen.jsx';
import { sendEmailVerification } from 'firebase/auth';
import { auth } from '../services/firebaseConfig';

const SignUpPage = () => {
  const navigate = useNavigate();
  const { user, loading } = useAuthStore();
  const [signingUp, setSigningUp] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [resending, setResending] = useState(false);
  const [resendSuccess, setResendSuccess] = useState(false);
  const [registeredEmail, setRegisteredEmail] = useState('');

  // If user is already authenticated and verified, redirect to feed
  useEffect(() => {
    if (user && !loading && user.emailVerified) {
      navigate('/feed');
    }
  }, [user, loading, navigate]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setSigningUp(true);
    setError(null);
    
    try {
      await signUpWithEmail(email, password);
      setSuccess(true);
      setRegisteredEmail(email);
    } catch (error) {
      console.error('Sign-up error:', error);
      let errorMessage = 'Failed to sign up. Please try again.';
      
      // Handle specific Firebase error codes
      if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'This email is already registered. Please sign in instead.';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Please enter a valid email address.';
      } else if (error.code === 'auth/weak-password') {
        errorMessage = 'Password should be at least 6 characters.';
      }
      
      setError(errorMessage);
    } finally {
      setSigningUp(false);
    }
  };

  const handleResendVerification = async () => {
    if (!registeredEmail) {
      setError('No email address available for verification. Please try signing up again.');
      return;
    }

    setResending(true);
    setError(null);
    setResendSuccess(false);
    
    try {
      // Get current user
      const currentUser = auth.currentUser;
      
      if (currentUser) {
        await sendEmailVerification(currentUser);
        setResendSuccess(true);
      } else {
        throw new Error('Unable to get current user. Please try signing up again.');
      }
    } catch (error) {
      console.error('Error resending verification:', error);
      setError('Failed to resend verification email. Please try again.');
    } finally {
      setResending(false);
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen bg-yt-black flex items-center justify-center p-4">
      <div className="bg-yt-darker rounded-lg shadow-xl max-w-md w-full p-8 animate-fade-in">
        <div className="text-center mb-8">
          <Link to="/" className="inline-block">
            <h1 className="text-3xl font-bold">
              <span className="text-yt-red">Meta</span>
              <span className="text-white">Blogger</span>
            </h1>
          </Link>
          <p className="text-yt-text-secondary mt-2">
            Create your MetaBlogger account
          </p>
        </div>

        {error && (
          <div className="bg-red-900 bg-opacity-30 border border-red-800 text-white p-3 rounded-md mb-6">
            {error}
          </div>
        )}

        {success ? (
          <div className="space-y-6">
            <div className="bg-green-900 bg-opacity-10 border border-green-800 text-white p-3 rounded-md mb-6">
              <p className="mb-2 font-bold">Verification email sent!</p>
              <p className="mb-2">Check your email and click the verification link to complete your registration!</p>
            </div>
            
            {resendSuccess && (
              <div className="bg-green-900 bg-opacity-30 border border-green-800 text-white p-3 rounded-md mb-6">
                Verification email resent successfully!
              </div>
            )}
            
            <Button
              onClick={handleResendVerification}
              variant="secondary"
              fullWidth
              className="py-3"
              disabled={resending}
            >
              {resending ? 'Sending...' : 'Resend Verification Email'}
            </Button>
            
            <Link to="/signin">
              <Button
                variant="outline"
                fullWidth
                className="py-3"
              >
                Go to Sign In
              </Button>
            </Link>
          </div>
        ) : (
          <form onSubmit={handleSignUp} className="space-y-6">
            <div>
              <label className="block text-yt-text-secondary mb-2" htmlFor="email">
                Email
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full bg-yt-light text-white px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-yt-red"
                required
              />
            </div>
            
            <div>
              <label className="block text-yt-text-secondary mb-2" htmlFor="password">
                Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full bg-yt-light text-white px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-yt-red"
                required
              />
            </div>

            <Button
              type="submit"
              variant="primary"
              fullWidth
              className="py-3"
              disabled={signingUp}
            >
              {signingUp ? 'Creating Account...' : 'Create Account'}
            </Button>

            <div className="text-center text-sm text-yt-text-secondary">
              <p>By continuing, you agree to MetaBlogger's Terms of Service and Privacy Policy.</p>
            </div>
          </form>
        )}

        <div className="mt-8 pt-6 border-t border-yt-light text-center">
          <p className="text-sm text-yt-text-secondary">
            Already have an account?{' '}
            <Link to="/signin" className="text-blue-400 hover:underline">
              Sign in
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;