// src/components/auth/PasswordResetHandler.jsx
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { verifyPasswordResetCode, confirmPasswordReset } from 'firebase/auth';
import { auth } from '../../services/firebaseConfig';
import LoadingScreen from '../common/LoadingScreen';
import Button from '../common/Button';

const PasswordResetHandler = () => {
  const [resetState, setResetState] = useState('verifying');
  const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [actionCode, setActionCode] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const verifyResetCode = async () => {
      try {
        // Get the action code from the URL query parameters
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get('oobCode');
        
        if (!code) {
          setResetState('error');
          setError('Invalid password reset link');
          return;
        }

        // Verify the password reset code
        await verifyPasswordResetCode(auth, code);
        setActionCode(code);
        setResetState('form');
      } catch (error) {
        console.error('Error verifying reset code:', error);
        setResetState('error');
        setError('This password reset link is invalid or has expired.');
      }
    };

    verifyResetCode();
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    
    setSubmitting(true);
    setError(null);
    
    try {
      // Confirm the password reset
      await confirmPasswordReset(auth, actionCode, password);
      setResetState('success');
    } catch (error) {
      console.error('Error resetting password:', error);
      setError('Failed to reset password. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  if (resetState === 'verifying') {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen bg-yt-black flex items-center justify-center p-4">
      <div className="bg-yt-darker rounded-lg shadow-xl max-w-md w-full p-8 animate-fade-in">
        <div className="text-center mb-8">
          <h1 className="text-2xl font-bold">
            <span className="text-yt-red">Meta</span>
            <span className="text-white">Blogger</span>
          </h1>
          <p className="text-yt-text-secondary mt-2">
            {resetState === 'form' ? 'Reset Your Password' : resetState === 'success' ? 'Password Reset Successful' : 'Password Reset Failed'}
          </p>
        </div>
        
        {error && (
          <div className="bg-red-900 bg-opacity-30 border border-red-800 text-white p-3 rounded-md mb-6">
            {error}
          </div>
        )}
        
        {resetState === 'form' && (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label className="block text-yt-text-secondary mb-2" htmlFor="password">
                New Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full bg-yt-light text-white px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-yt-red"
                required
              />
            </div>
            
            <div>
              <label className="block text-yt-text-secondary mb-2" htmlFor="confirm-password">
                Confirm New Password
              </label>
              <input
                id="confirm-password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full bg-yt-light text-white px-3 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-yt-red"
                required
              />
            </div>

            <Button
              type="submit"
              variant="primary"
              fullWidth
              className="py-3"
              disabled={submitting}
            >
              {submitting ? 'Resetting Password...' : 'Reset Password'}
            </Button>
          </form>
        )}
        
        {resetState === 'success' && (
          <>
            <div className="bg-green-900 bg-opacity-30 border border-green-800 text-white p-4 rounded-md mb-6">
              <p className="mb-2">Your password has been reset successfully!</p>
              <p>You can now sign in with your new password.</p>
            </div>
            <Button
              onClick={() => navigate('/signin')}
              variant="primary"
              fullWidth
              className="py-3"
            >
              Go to Sign In
            </Button>
          </>
        )}
        
        {resetState === 'error' && (
          <div className="space-y-6">
            <div className="bg-red-900 bg-opacity-30 border border-red-800 text-white p-4 rounded-md">
              <p className="mb-2">Password Reset Link Invalid</p>
              <p>The password reset link has expired or is invalid. Please request a new password reset link.</p>
            </div>
            <Button
              onClick={() => navigate('/signin')}
              variant="primary"
              fullWidth
              className="py-3"
            >
              Return to Sign In
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordResetHandler;