// src/components/content/ContentDisplay.jsx
import React from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import SessionGrid from '../feed/SessionGrid';
import ErrorMessage from '../common/ErrorMessage';
import LoadingScreen from '../common/LoadingScreen';
import Button from '../common/Button';

/**
 * Reusable component for displaying content with consistent loading/error states
 */
const ContentDisplay = ({ 
  title,
  content, 
  isLoading, 
  error, 
  clearError, 
  refetch,
  showRefreshButton = true,
  className = "",
}) => {
  // Determine if we should show the full-page loading screen
  // Only show it if loading AND we don't have any content yet
  const showFullLoading = isLoading && (!content || content.length === 0);
  
  return (
    <div className={`animate-fade-in ${className}`}>
      {title && (
        <div className="mb-6 flex justify-between items-center">
          <h1 className="text-2xl font-bold text-yt-text">{title}</h1>
          
          {showRefreshButton && (
            <Button
              variant="secondary"
              size="sm"
              icon={<FiRefreshCw />}
              onClick={() => refetch()}
              disabled={isLoading}
              className={`${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              Refresh
            </Button>
          )}
        </div>
      )}
      
      {showFullLoading ? (
        <LoadingScreen />
      ) : (
        <SessionGrid
          content={content || []}
          isLoading={isLoading && !showFullLoading} // Use isLoading but don't double up on loaders
          error={error}
        />
      )}
      
      {error && <ErrorMessage message={error} onClose={clearError} />}
    </div>
  );
};

export default ContentDisplay;