// src/App.jsx
import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import MainLayout from "./components/layout/MainLayout";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import EmailVerificationHandler from "./components/auth/EmailVerificationHandler";
import PasswordResetHandler from "./components/auth/PasswordResetHandler";
import IndexPage from "./pages/IndexPage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import FeedPage from "./pages/FeedPage";
import SessionPage from "./pages/SessionPage";
import CreatorPage from "./pages/CreatorPage";
import SettingsPage from "./pages/SettingsPage";
import ChannelPage from "./pages/ChannelPage";
import { initAuthListener } from "./services/authService";

function App() {
  // Initialize auth listener when app loads
  useEffect(() => {
    const unsubscribe = initAuthListener();

    // Clean up listener on unmount
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  return (
    <div className="dark h-full">
      <Routes>
        {/* Public routes */}
        <Route path="/" element={<IndexPage />} />
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/verify-email" element={<EmailVerificationHandler />} />
        <Route path="/reset-password" element={<PasswordResetHandler />} />

        {/* Protected routes with MainLayout */}
        <Route element={<ProtectedRoute />}>
          <Route element={<MainLayout />}>
            <Route path="/feed" element={<FeedPage />} />
            <Route path="/feed/:id" element={<SessionPage />} />
            <Route path="/creator" element={<CreatorPage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/channels/:channelId" element={<ChannelPage />} />
          </Route>
        </Route>
      </Routes>
    </div>
  );
}

export default App;