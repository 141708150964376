// src/services/firestoreService.js
import { db } from './firebaseConfig';
import { 
  getDocs, 
  getDoc, 
  doc, 
  query, 
  where, 
  orderBy,
  collectionGroup 
} from 'firebase/firestore';

/**
 * Fetches all completed content from all channels
 * @returns {Promise<Array>} Array of completed content documents
 */
export const fetchCompletedContent = async () => {
  try {
    // Use collection group query with sorting
    // Note: This requires a composite index on status and script_title
    const contentQuery = query(
      collectionGroup(db, 'content'),
      where('status', '==', 'completed'),
      orderBy('script_title', 'desc')
    );

    const contentSnapshot = await getDocs(contentQuery);
    
    return contentSnapshot.docs.map(doc => {
      // Get the full path to extract channel_id
      const fullPath = doc.ref.path;
      // Path format is: "channels/{channelId}/content/{contentId}"
      const pathParts = fullPath.split('/');
      const channelId = pathParts[1];
      
      return {
        content_id: doc.id,
        channel_id: channelId,
        ...doc.data()
      };
    });
    
  } catch (error) {
    console.error('Error fetching completed content:', error);
    throw error;
  }
};

/**
 * Fetches all content for a specific channel
 * @param {string} channelId - The ID of the channel
 * @returns {Promise<Array>} Array of channel content documents
 */
export const fetchChannelContent = async (channelId) => {
  try {
    if (!channelId) {
      throw new Error('Channel ID is required');
    }

    // Create a query for content in this specific channel
    const contentQuery = query(
      collectionGroup(db, 'content'),
      where('channel_id', '==', channelId),
      where('status', '==', 'completed'),
      orderBy('script_title', 'desc')
    );

    const contentSnapshot = await getDocs(contentQuery);
    
    return contentSnapshot.docs.map(doc => {
      return {
        content_id: doc.id,
        channel_id: channelId,
        ...doc.data()
      };
    });
  } catch (error) {
    console.error(`Error fetching content for channel ${channelId}:`, error);
    throw error;
  }
};

/**
 * Fetches a specific content document
 * @param {string} channelId - The ID of the channel
 * @param {string} contentId - The ID of the content
 * @returns {Promise<Object>} The content document
 */
export const fetchContent = async (channelId, contentId) => {
  try {
    const contentRef = doc(db, `channels/${channelId}/content/${contentId}`);
    const contentSnapshot = await getDoc(contentRef);
    
    if (contentSnapshot.exists()) {
      return {
        content_id: contentId,
        channel_id: channelId,
        ...contentSnapshot.data()
      };
    } else {
      throw new Error(`Content with ID ${contentId} not found`);
    }
  } catch (error) {
    console.error(`Error fetching content ${contentId}:`, error);
    throw error;
  }
};