import React, { useState, useRef } from "react";
import { FiX, FiUpload, FiImage, FiCheck } from "react-icons/fi";
import Button from "../common/Button";
import { createContent } from "../../services/contentService";
import { validateContentForm, validateThumbnail } from "../../utils/contentValidation";
import useAuthStore from "../../store/authStore";

const CreateContentModal = ({ channel, onClose, onContentCreated }) => {
  const { user } = useAuthStore();
  const [loading, setLoading] = useState(false);
  const [processingContent, setProcessingContent] = useState(false);
  const [error, setError] = useState(null);
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [formData, setFormData] = useState({
    script_title: "",
    content_description: "", // Added for the new field
    original_script: "",
    content_knowledge_bank: "",
  });
  const [formErrors, setFormErrors] = useState({});
  
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    
    // Clear error when field is updated
    if (formErrors[name]) {
      setFormErrors((prev) => ({ ...prev, [name]: null }));
    }
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Validate the file
    const thumbnailError = validateThumbnail(file);
    if (thumbnailError) {
      setFormErrors(prev => ({ ...prev, ...thumbnailError }));
      return;
    }
    
    setThumbnail(file);
    setFormErrors((prev) => ({ ...prev, thumbnail: null }));
    
    // Create preview URL
    const reader = new FileReader();
    reader.onloadend = () => {
      setThumbnailPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const validateForm = () => {
    const errors = validateContentForm(formData, thumbnail, user?.email);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setLoading(true);
    setError(null);
    
    try {
      // Create FormData object for multipart form data
      const formDataObj = new FormData();
      formDataObj.append("channel_id", channel.channel_id);
      formDataObj.append("script_title", formData.script_title);
      formDataObj.append("content_description", formData.content_description);
      formDataObj.append("original_script", formData.original_script);
      formDataObj.append("content_knowledge_bank", formData.content_knowledge_bank);
      formDataObj.append("thumbnail", thumbnail);
      
      // Submit the form data
      const response = await createContent(formDataObj);
      
      if (response.success) {
        // Now we need to wait for processing to complete
        setProcessingContent(true);
        
        // For demo, we'll just wait a few seconds
        // In production, you might poll the server for status
        setTimeout(() => {
          setProcessingContent(false);
          setLoading(false);
          onContentCreated(response.content_id);
        }, 5000);
      } else {
        setError(response.message || "Failed to create content");
        setLoading(false);
      }
    } catch (err) {
      setError(err.message || "An unexpected error occurred");
      setLoading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 p-4 animate-fade-in">
      <div className="bg-yt-darker rounded-lg max-w-3xl w-full max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center p-4 border-b border-yt-light">
          <h2 className="text-xl font-bold text-yt-text">
            Create Content for {channel.channel_title}
          </h2>
          <button
            onClick={onClose}
            className="text-yt-text-secondary hover:text-yt-text p-2 rounded-full hover:bg-yt-light"
            disabled={loading || processingContent}
          >
            <FiX size={24} />
          </button>
        </div>

        {/* Processing Content Overlay */}
          {processingContent && (
            <div className="absolute inset-0 bg-yt-black bg-opacity-90 flex flex-col items-center justify-center z-10 rounded-lg">
              <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-yt-red mb-4"></div>
              <h3 className="text-xl font-bold text-yt-text mb-2">Processing Content</h3>
              <p className="text-yt-text-secondary mb-4">
                This typically takes 15-30 seconds
              </p>
              <div className="w-64 h-2 bg-yt-darker rounded-full overflow-hidden">
                <div className="h-full bg-yt-red animate-pulse"></div>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit} className="p-6">
            {error && (
              <div className="bg-red-900 bg-opacity-30 border border-red-800 text-white p-3 rounded-md mb-4">
                {error}
              </div>
            )}

            <div className="mb-4">
              <label className="block text-yt-text font-medium mb-2">
                Title*
              </label>
              <input
                type="text"
                name="script_title"
                value={formData.script_title}
                onChange={handleChange}
                className={`w-full bg-yt-black text-yt-text rounded-md p-3 border ${
            formErrors.script_title ? "border-red-500" : "border-yt-light"
                }`}
                placeholder="Title for your content (3-40 characters)"
                disabled={loading}
              />
              {formErrors.script_title && (
                <p className="text-red-500 text-sm mt-1">{formErrors.script_title}</p>
              )}
              <p className="text-yt-text-secondary text-xs mt-1 text-right">
                characters: {formData.script_title.length}/40
              </p>
            </div>

            <div className="mb-4">
              <label className="block text-yt-text font-medium mb-2">
                Description*
              </label>
              <textarea
                name="content_description"
                value={formData.content_description}
                onChange={handleChange}
                className={`w-full bg-yt-black text-yt-text rounded-md p-3 border ${
            formErrors.content_description ? "border-red-500" : "border-yt-light"
                } h-32`}
                placeholder="Describe your content (minimum 300 characters). This description will be visible to the public; however, it will not be stored in your AI Knowledge Bank."
                disabled={loading}
              ></textarea>
              {formErrors.content_description && (
                <p className="text-red-500 text-sm mt-1">{formErrors.content_description}</p>
              )}
              <p className="text-yt-text-secondary text-xs mt-1 text-right">
                characters: {formData.content_description?.length || 0}/2500
              </p>
            </div>

            <div className="mb-4">
              <label className="block text-yt-text font-medium mb-2">
                Script*
              </label>
              <textarea
                name="original_script"
                value={formData.original_script}
                onChange={handleChange}
                className={`w-full bg-yt-black text-yt-text rounded-md p-3 border ${
            formErrors.original_script ? "border-red-500" : "border-yt-light"
                } h-40`}
                placeholder="The Script for your content (minimum 1,000 characters). This will be narrated precisely according to the text you insert here without changes. Include only the actual Script without any context (which can be put into the AI Knowledge Bank). Try to make your Script clear and structured."
                disabled={loading}
              ></textarea>
              {formErrors.original_script && (
                <p className="text-red-500 text-sm mt-1">{formErrors.original_script}</p>
              )}
              <p className="text-yt-text-secondary text-xs mt-1 text-right">
                characters: {formData.original_script.length}/10,000
              </p>
            </div>

            <div className="mb-4">
              <label className="block text-yt-text font-medium mb-2">
                AI Knowledge Bank*
              </label>
              <textarea
                name="content_knowledge_bank"
                value={formData.content_knowledge_bank}
                onChange={handleChange}
                className={`w-full bg-yt-black text-yt-text rounded-md p-3 border ${
            formErrors.content_knowledge_bank ? "border-red-500" : "border-yt-light"
                } h-40`}
                placeholder="Create an AI Knowledge Bank for your Session (minimum 600 characters). The AI Knowledge Bank isn't visible to the public. This will be used to improve AI responses in interactive mode. For each Session, you'll create a separate AI Knowledge Bank, which will be relevant only for that particular Session. You can include some information that enriches your Script and can be used to provide quality and personalized AI responses to Users. It can include answers to possible questions that the User might ask during the Session, or anything else that could be useful. Try to be concise and clear."
                disabled={loading}
              ></textarea>
              {formErrors.content_knowledge_bank && (
                <p className="text-red-500 text-sm mt-1">{formErrors.content_knowledge_bank}</p>
              )}
              <p className="text-yt-text-secondary text-xs mt-1 text-right">
                characters: {formData.content_knowledge_bank.length}/5,000
              </p>
            </div>

            {/* Thumbnail Upload */}
          <div className="mb-6">
            <label className="block text-yt-text font-medium mb-2">
              Thumbnail*
            </label>
            
            <input
              type="file"
              ref={fileInputRef}
              onChange={handleThumbnailChange}
              accept="image/jpeg, image/png"
              className="hidden"
              disabled={loading}
            />
            
            <div 
              onClick={triggerFileInput}
              className={`
                border-2 border-dashed rounded-md p-6 text-center cursor-pointer
                ${thumbnailPreview ? 'border-green-500' : formErrors.thumbnail ? 'border-red-500' : 'border-yt-light'}
                ${loading ? 'opacity-50 cursor-not-allowed' : 'hover:border-yt-red'}
              `}
            >
              {thumbnailPreview ? (
                <div className="flex flex-col items-center">
                  <div className="relative mb-2">
                    <img 
                      src={thumbnailPreview} 
                      alt="Thumbnail Preview" 
                      className="max-h-40 max-w-full rounded"
                    />
                    <div className="absolute -top-2 -right-2 bg-green-500 rounded-full p-1">
                      <FiCheck className="text-white" size={14} />
                    </div>
                  </div>
                  <p className="text-yt-text-secondary text-sm">
                    Click to change thumbnail
                  </p>
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  <FiImage size={48} className="text-yt-text-secondary mb-3" />
                  <p className="text-yt-text mb-1">
                    Drop your thumbnail here or click to browse
                  </p>
                  <p className="text-yt-text-secondary text-sm">
                    JPG or PNG
                  </p>
                </div>
              )}
            </div>
            
            {formErrors.thumbnail && (
              <p className="text-red-500 text-sm mt-1">{formErrors.thumbnail}</p>
            )}
          </div>

          <div className="flex justify-end">
            <Button
              variant="secondary"
              type="button"
              onClick={onClose}
              className="mr-3"
              disabled={loading || processingContent}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              type="submit"
              icon={<FiUpload size={16} />}
              disabled={loading || processingContent}
            >
              {loading ? "Uploading..." : "Create Content"}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateContentModal;