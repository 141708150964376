// src/pages/CreatorPage.jsx
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../store/authStore";
import { FiPlus } from "react-icons/fi";
import Button from "../components/common/Button";
import LoadingScreen from "../components/common/LoadingScreen";
import ChannelCard from "../components/creator/ChannelCard";
import CreateChannelModal from "../components/creator/CreateChannelModal";
import CreateContentModal from "../components/creator/CreateContentModal";
import { fetchUserChannels } from "../services/channelService";

const CreatorPage = () => {
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const [showChannelModal, setShowChannelModal] = useState(false);
  const [showContentModal, setShowContentModal] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);

  // Fetch user's channels
  const {
    data: channels,
    isLoading,
    error,
    refetch,
  } = useQuery(
    ["user-channels", user?.uid],
    () => fetchUserChannels(user.uid),
    {
      enabled: !!user?.uid,
      staleTime: 60000, // 1 minute
    }
  );

  // Handle opening content creation for a specific channel
  const handleCreateContent = (channel) => {
    setSelectedChannel(channel);
    setShowContentModal(true);
  };

  // Handle successful channel creation
  const handleChannelCreated = () => {
    setShowChannelModal(false);
    refetch(); // Refresh the channels list
  };

  // Handle successful content creation
  const handleContentCreated = (contentId) => {
    setShowContentModal(false);
    // Navigate to the session page for the newly created content
    navigate(`/feed/${contentId}`);
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="animate-fade-in">
      <div className="mb-6 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-yt-text">Metablogger Studio</h1>

        <Button
          variant="primary"
          size="md"
          icon={<FiPlus />}
          onClick={() => setShowChannelModal(true)}
        >
          Create Channel
        </Button>
      </div>

      {error && (
        <div className="bg-red-900 bg-opacity-30 border border-red-800 text-white p-4 rounded-md mb-6">
          Error loading your channels: {error.message}
        </div>
      )}

      {/* Channel Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mb-8 auto-rows-fr">
        {channels?.map((channel) => (
          <ChannelCard
            key={channel.channel_id}
            channel={channel}
            onCreateContent={() => handleCreateContent(channel)}
          />
        ))}

        {/* Create New Channel Card (only show if no channels or as the last card) */}
        {(!channels || channels.length === 0) && (
          <div
            onClick={() => setShowChannelModal(true)}
            className="bg-yt-darker rounded-lg border-2 border-dashed border-yt-light flex flex-col items-center justify-center p-6 cursor-pointer hover:bg-yt-light transition-colors aspect-square"
          >
            <div className="bg-yt-red rounded-full p-3 mb-4">
              <FiPlus size={24} className="text-white" />
            </div>
            <p className="text-yt-text font-medium text-xl">
              Create Your First Channel
            </p>
            <p className="text-yt-text-secondary text-sm mt-2 text-center">
              Start by creating a channel for your content
            </p>
          </div>
        )}
      </div>

      {/* Section for existing channels when available */}
      {channels && channels.length > 0 && (
        <div className="mb-6">
          <h2 className="text-xl font-medium text-yt-text mb-4">
            Your Channels
          </h2>
          <p className="text-yt-text-secondary">
            Select a channel to create new content or click the Create Channel
            button above to add a new one.
          </p>
        </div>
      )}

      {/* Empty state when no channels */}
      {(!channels || channels.length === 0) && (
        <div className="bg-yt-darker rounded-lg p-6 text-center">
          <h2 className="text-xl font-medium text-yt-text mb-4">
            Welcome to Metablogger Studio
          </h2>
          <p className="text-yt-text-secondary max-w-lg mx-auto">
            Create your first channel to start publishing content. Each channel
            can have its own focus, style, and voice.
          </p>
        </div>
      )}

      {/* Create Channel Modal */}
      {showChannelModal && (
        <CreateChannelModal
          onClose={() => setShowChannelModal(false)}
          onChannelCreated={handleChannelCreated}
        />
      )}

      {/* Create Content Modal */}
      {showContentModal && selectedChannel && (
        <CreateContentModal
          channel={selectedChannel}
          onClose={() => setShowContentModal(false)}
          onContentCreated={handleContentCreated}
        />
      )}
    </div>
  );
};

export default CreatorPage;
