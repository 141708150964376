// src/services/storageService.js
import { storage } from './firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';

/**
 * Cache objects for asset URLs to avoid redundant fetches
 */
const thumbnailCache = new Map();
const audioCache = new Map();

/**
 * Helper function to create a delay promise
 * @param {number} ms - Milliseconds to delay
 * @returns {Promise} Promise that resolves after the delay
 */
const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Simplifies a storage path by extracting the relevant part
 * @param {string} path - Original path or URL
 * @returns {string} The simplified path or the original if already simple
 */
const simplifyPath = (path) => {
  if (!path) return null;
  
  // If it's a complete Google Storage URL, use it directly
  if (path.startsWith('https://storage.googleapis.com/')) {
    // Either return the whole URL, or extract the path part if needed
    return path; // We can use these URLs directly now
  }
  
  // Extract path from metabstorage URLs
  if (path.includes('/metabstorage/')) {
    return path.split('/metabstorage/')[1];
  }
  
  // Extract path from gs:// format
  if (path.startsWith('gs://metabstorage/')) {
    return path.substring('gs://metabstorage/'.length);
  }
  
  // If none of the above patterns match, return the original
  return path;
};

/**
 * Generic function to get a download URL from Storage with retries
 * @param {string} path - Path to the file
 * @param {Map} cache - Cache Map to use
 * @param {string} fileType - Type of file (for logging)
 * @returns {Promise<string|null>} Download URL or null if unavailable
 */
const getFileUrl = async (path, cache, fileType = 'file') => {
  if (!path) return null;
  
  // Check cache first
  if (cache.has(path)) {
    return cache.get(path);
  }
  
  try {
    // Direct Google Storage URLs can be used as-is
    if (path.startsWith('https://storage.googleapis.com/')) {
      cache.set(path, path);
      return path;
    }
    
    // Simplify the path if it's in a different format
    const storagePath = simplifyPath(path);
    
    if (!storagePath) {
      console.warn(`[StorageService] Invalid path: ${path}`);
      return null;
    }
    
    // Implement retry logic
    let retryCount = 0;
    const maxRetries = 3;
    
    while (retryCount < maxRetries) {
      try {
        // Get the download URL
        const storageRef = ref(storage, storagePath);
        const url = await getDownloadURL(storageRef);
        
        // Cache the successful result
        cache.set(path, url);
        
        return url;
      } catch (error) {
        retryCount++;
        
        if (retryCount >= maxRetries) {
          console.warn(`[StorageService] Failed to load ${fileType} after ${maxRetries} attempts`);
          return null;
        }
        
        // Wait with exponential backoff before retrying
        const delayMs = 500 * Math.pow(2, retryCount);
        await delay(delayMs);
      }
    }
  } catch (error) {
    console.error(`[StorageService] Error getting ${fileType} URL:`, error);
    return null;
  }
};

/**
 * Gets the download URL for a thumbnail with retries
 * @param {string} thumbnailPath - Path to thumbnail from Firestore
 * @returns {Promise<string|null>} Download URL for the thumbnail or null if unavailable
 */
export const getThumbnailUrl = async (thumbnailPath) => {
  // Direct Google Storage URLs can be used as-is
  if (thumbnailPath?.startsWith('https://storage.googleapis.com/')) {
    thumbnailCache.set(thumbnailPath, thumbnailPath);
    return thumbnailPath;
  }
  
  return getFileUrl(thumbnailPath, thumbnailCache, 'thumbnail');
};

/**
 * Gets the download URL for an audio file with retries
 * @param {string} audioPath - Path to audio file from Firestore
 * @returns {Promise<string|null>} Download URL for the audio file or null if unavailable
 */
export const getAudioUrl = async (audioPath) => {
  // Direct Google Storage URLs can be used as-is
  if (audioPath?.startsWith('https://storage.googleapis.com/')) {
    audioCache.set(audioPath, audioPath);
    return audioPath;
  }
  
  return getFileUrl(audioPath, audioCache, 'audio');
};