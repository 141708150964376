// src/components/layout/Header.jsx
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom"; // Link is already imported
import { FiSearch, FiMenu, FiUser, FiLogOut } from "react-icons/fi";
import useUiStore from "../../store/uiStore";
import useAuthStore from "../../store/authStore";
import { signOut } from "../../services/authService";
import Button from "../common/Button";

const Header = () => {
  const navigate = useNavigate();
  const { toggleSidebar, searchQuery, setSearchQuery } = useUiStore();
  const { user } = useAuthStore();
  const [isFocused, setIsFocused] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);

  const dropdownRef = useRef(null);

  // ... (keep existing useEffect, handleSearch, handleSignOut functions)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    // Handle search logic here
    console.log("Searching for:", searchQuery);
  };

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };


  return (
    <header className="fixed top-0 right-0 left-0 h-14 bg-yt-black flex items-center justify-between px-4 z-20 border-b border-yt-darker">
      <div className="flex items-center">
        <button
          onClick={toggleSidebar}
          className="mr-4 p-2 rounded-full hover:bg-yt-darker text-yt-text"
          aria-label="Toggle sidebar"
        >
          <FiMenu size={20} />
        </button>

        {/* Wrap the logo/text div with a Link */}
        <Link to="/" className="hidden sm:flex items-center text-xl font-bold cursor-pointer">
          <span className="text-yt-red">Meta</span>
          <span className="text-white">Blogger</span>
          <span className="text-white text-xs font-normal ml-1">(beta)</span>
        </Link>
      </div>

      {/* ... (keep existing search form) */}
      <div className="flex-grow max-w-2xl mx-4">
         <form onSubmit={handleSearch} className="flex w-full">
           <div
             className={`relative flex-grow rounded-l-full border ${
               isFocused ? "border-blue-500" : "border-yt-darker"
             } bg-yt-black`}
           >
             <input
               type="text"
               placeholder="Search"
               value={searchQuery}
               onChange={(e) => setSearchQuery(e.target.value)}
               onFocus={() => setIsFocused(true)}
               onBlur={() => setIsFocused(false)}
               className="w-full py-2 px-4 bg-transparent text-yt-text placeholder-yt-text-secondary focus:outline-none rounded-l-full"
             />
           </div>
           <button
             type="submit"
             className="flex items-center justify-center bg-yt-darker hover:bg-yt-light px-5 rounded-r-full border border-yt-darker"
           >
             <FiSearch size={18} className="text-yt-text" />
           </button>
         </form>
       </div>


      {/* ... (keep existing user menu/sign-in button) */}
       <div className="flex items-center">
         {user ? (
           <div className="relative" ref={dropdownRef}>
             <button
               onClick={() => setShowDropdown(!showDropdown)}
               className="ml-4 p-2 rounded-full hover:bg-yt-darker text-yt-text"
               aria-label="User menu"
             >
               <FiUser size={20} />
             </button>

             {showDropdown && (
               <div className="absolute right-0 mt-2 w-48 bg-yt-darker rounded-md shadow-lg z-30 overflow-hidden">
                 <div className="py-2 px-4 border-b border-yt-light">
                   <p className="text-sm font-medium text-white truncate">{user.displayName}</p>
                   <p className="text-xs text-yt-text-secondary truncate">{user.email}</p>
                 </div>
                 <button
                   onClick={handleSignOut}
                   className="flex items-center w-full px-4 py-2 text-sm text-yt-text hover:bg-yt-light"
                 >
                   <FiLogOut className="mr-2" />
                   Sign out
                 </button>
               </div>
             )}
           </div>
         ) : (
           <Link to="/signin">
             <Button
               variant="outline"
               size="sm"
               className="ml-4 flex items-center"
               icon={<FiUser size={16} />}
             >
               Sign In
             </Button>
           </Link>
         )}
       </div>
    </header>
  );
};

export default Header;