import React from 'react';
import { FiSettings } from 'react-icons/fi';

const SettingsPage = () => {
  return (
    <div className="max-w-4xl mx-auto p-4 animate-fade-in">
      <div className="bg-yt-darker rounded-lg p-8 text-center">
        <div className="inline-block p-6 rounded-full bg-yt-black mb-6">
          <FiSettings size={48} className="text-yt-text-secondary" />
        </div>
        
        <h1 className="text-2xl font-bold text-yt-text mb-4">
          Settings - Coming Soon
        </h1>
        
        <p className="text-yt-text-secondary max-w-lg mx-auto mb-8">
          The Settings page is currently under development. 
          Here, you'll be able to configure your account, preferences, 
          and personalize your MetaBlogger experience.
        </p>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 text-left mt-8">
          <div className="bg-yt-black p-5 rounded-lg">
            <h2 className="text-lg font-medium text-yt-text mb-2">Account Settings</h2>
            <p className="text-yt-text-secondary text-sm">
              Manage your profile, privacy options, and connected accounts.
            </p>
          </div>
          
          <div className="bg-yt-black p-5 rounded-lg">
            <h2 className="text-lg font-medium text-yt-text mb-2">Appearance</h2>
            <p className="text-yt-text-secondary text-sm">
              Customize the look and feel of your MetaBlogger interface.
            </p>
          </div>
          
          <div className="bg-yt-black p-5 rounded-lg">
            <h2 className="text-lg font-medium text-yt-text mb-2">Notifications</h2>
            <p className="text-yt-text-secondary text-sm">
              Configure when and how you receive updates about new content and interactions.
            </p>
          </div>
          
          <div className="bg-yt-black p-5 rounded-lg">
            <h2 className="text-lg font-medium text-yt-text mb-2">Playback & Interaction</h2>
            <p className="text-yt-text-secondary text-sm">
              Adjust audio quality, interaction preferences, and AI engagement levels.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;