// src/components/feed/SessionCard.jsx
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiThumbsUp, FiEye } from "react-icons/fi";

// Helper function to format numbers with K/M suffix
const formatNumber = (num) => {
  if (num === undefined || num === null) return "0";
  if (num >= 1000000) return (num / 1000000).toFixed(1) + "M";
  if (num >= 1000) return (num / 1000).toFixed(1) + "K";
  return num.toString();
};

// Maximum time to show loading state before showing placeholder
const LOADING_TIMEOUT = 5000; // 5 seconds

const SessionCard = ({ content }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadingTimedOut, setLoadingTimedOut] = useState(false);
  const [imageError, setImageError] = useState(false);
  const navigate = useNavigate();
  
  const {
    content_id,
    title,
    thumbnailUrl,
    thumbnailError,
    channel_title,
    channel_id,
    view_count = 0,
    like_count = 0,
    duration,
  } = content;
  
  // Handle channel title click
  const handleChannelClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigate(`/channels/${channel_id}`);
  };
  
  // Reset loading state when thumbnail URL changes or on component mount
  useEffect(() => {
    // Reset states
    setIsLoading(true);
    setLoadingTimedOut(false);
    setImageError(false);
    
    // If we already know there was an error from the hook
    if (thumbnailError) {
      setIsLoading(false);
      setImageError(true);
      return;
    }
    
    // If we don't have a thumbnailUrl yet, start loading timeout
    if (thumbnailUrl === null) {
      // Set a timeout to show placeholder if loading takes too long
      const timer = setTimeout(() => {
        setLoadingTimedOut(true);
      }, LOADING_TIMEOUT);
      
      return () => clearTimeout(timer);
    } else {
      // We have a URL, so pre-load the image
      const img = new Image();
      
      img.onload = () => {
        setIsLoading(false);
      };
      
      img.onerror = () => {
        setIsLoading(false);
        setImageError(true);
      };
      
      img.src = thumbnailUrl;
    }
  }, [thumbnailUrl, thumbnailError]);
  
  // Placeholder image as data URI
  const placeholderImage =
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 180' width='320' height='180'%3E%3Crect width='320' height='180' fill='%23212121'/%3E%3Ctext x='50%25' y='50%25' dominant-baseline='middle' text-anchor='middle' font-family='sans-serif' font-size='18' fill='%23aaaaaa'%3ENo Thumbnail%3C/text%3E%3C/svg%3E";

  // Determine what image src to use
  const imageSrc = imageError || loadingTimedOut ? placeholderImage : thumbnailUrl || placeholderImage;
  
  // Determine if we should show the loading spinner
  const showLoadingSpinner = isLoading && !loadingTimedOut && !imageError;

  return (
    <div className="hover-scale">
      <Link to={`/feed/${content_id}`} className="block">
        <div className="bg-yt-darker rounded-lg overflow-hidden transition-transform duration-200 hover:bg-yt-light">
          {/* Thumbnail */}
          <div className="relative">
            {/* Show loading spinner only if still loading and not timed out */}
            {showLoadingSpinner && (
              <div className="absolute inset-0 flex items-center justify-center bg-yt-darker">
                <div className="w-8 h-8 border-2 border-yt-red border-t-transparent rounded-full animate-spin"></div>
              </div>
            )}
            
            {/* Always render the image element to handle load events */}
            <img
              src={imageSrc}
              alt={title}
              className={`w-full aspect-video object-cover ${showLoadingSpinner ? 'opacity-0' : 'opacity-100'}`}
              onLoad={() => { 
                setIsLoading(false);
              }}
              onError={(e) => {
                setIsLoading(false);
                setImageError(true);
                
                // Only set new src if not already using placeholder
                if (e.target.src !== placeholderImage) {
                  e.target.src = placeholderImage;
                }
              }}
            />
            
            {/* Duration indicator */}
            {duration && (
              <div className="absolute bottom-2 right-2 bg-black bg-opacity-80 px-1 py-0.5 text-xs text-white rounded">
                {duration}
              </div>
            )}
          </div>

          {/* Content Info */}
          <div className="p-3">
            <h3 className="text-sm font-medium line-clamp-2 text-yt-text mb-1">
              {title}
            </h3>

            {/* Channel title - clickable but not a nested link */}
            <div 
              onClick={handleChannelClick}
              className="text-xs text-yt-text-secondary hover:text-yt-red cursor-pointer"
            >
              {channel_title || ""}
            </div>

            <div className="flex items-center mt-2 text-xs text-yt-text-secondary">
              <div className="flex items-center mr-3">
                <FiEye size={14} className="mr-1" />
                <span>{formatNumber(view_count)}</span>
              </div>
              <div className="flex items-center">
                <FiThumbsUp size={14} className="mr-1" />
                <span>{formatNumber(like_count)}</span>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SessionCard;