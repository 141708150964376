import React from 'react';

const variants = {
  primary: 'bg-yt-red hover:bg-red-700 text-white',
  secondary: 'bg-yt-darker hover:bg-yt-light text-white',
  outline: 'bg-transparent border border-yt-light hover:bg-yt-darker text-white',
};

const sizes = {
  sm: 'text-xs py-1 px-2',
  md: 'text-sm py-2 px-4',
  lg: 'text-base py-3 px-6',
};

const Button = ({
  children,
  className = '',
  variant = 'primary',
  size = 'md',
  fullWidth = false,
  icon,
  onClick,
  ...props
}) => {
  return (
    <button
      className={`
        ${variants[variant] || variants.primary}
        ${sizes[size] || sizes.md}
        ${fullWidth ? 'w-full' : ''}
        rounded-md font-medium transition-colors duration-200 ease-in-out focus:outline-none
        flex items-center justify-center
        ${className}
      `}
      onClick={onClick}
      {...props}
    >
      {icon && <span className={`${children ? 'mr-2' : ''}`}>{icon}</span>}
      {children}
    </button>
  );
};

export default Button;