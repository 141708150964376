import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../components/common/Button";
import { MdPlayArrow } from "react-icons/md";
import useAuthStore from "../store/authStore";
import LoadingScreen from "../components/common/LoadingScreen";

const IndexPage = () => {
  const navigate = useNavigate();
  const { user, loading } = useAuthStore();

  const handleGetStarted = () => {
    if (user) {
      navigate("/feed");
    } else {
      navigate("/signin");
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <div className="min-h-screen bg-yt-black flex flex-col">
      {/* Simple header with just the logo */}
      <header className="p-4 flex justify-between items-center">
        <div className="text-3xl font-bold">

        </div>
        
        {user && (
          <Button
            onClick={() => navigate("/feed")}
            variant="outline"
            size="sm"
            className="px-4"
          >
            Go to App
          </Button>
        )}
      </header>

      <div className="flex flex-col items-center justify-center flex-grow text-center px-4">
        <h1 className="text-4xl md:text-6xl font-bold mb-6 text-yt-text">
                    <span className="text-yt-red">Meta</span>
          <span className="text-white">Blogger</span>
          <span className="text-white text-xs font-normal ml-1">(beta)</span>
        </h1>

        <p className="text-lg md:text-2xl text-yt-text-secondary max-w-2xl mb-12">
          Interactive AI content platform where you can listen, engage, and relax
          with AI podcasters
        </p>

        <div className="animate-pulse">
          <Button
            onClick={handleGetStarted}
            size="lg"
            className="shadow-lg text-lg py-4 px-8"
            icon={<MdPlayArrow size={24} />}
          >
            {user ? "Launch App" : "Get Started"}
          </Button>
        </div>

        <div className="mt-16 bg-yt-darker rounded-lg p-6 max-w-lg">
          <h2 className="text-2xl font-semibold mb-4">Why MetaBlogger?</h2>
          <ul className="text-left text-yt-text-secondary space-y-3 text-lg">
            <li>• Listen and relax</li>
            <li>• Interact with AI content when you want</li>
            <li>• Discover new topics and perspectives</li>
            <li>• Enjoy a more natural, engaging experience</li>
          </ul>
        </div>
      </div>

      <footer className="p-6 text-center text-yt-text-secondary">
        <p>© 2025 MetaBlogger</p>
      </footer>
    </div>
  );
};

export default IndexPage;